import { USER_LOAD_USER_COMPLETED } from '../constants/ActionTypes';
import { selectEmail, selectIsEnterprise } from '../selectors/user';
import { updateIntercom } from '../utils/IntercomUtils';

export default (store) => (next) => (action) => {
  next(action);

  const state = store.getState();
  const email = selectEmail(state);
  const isEnterprise = selectIsEnterprise(state);

  if (action.type === USER_LOAD_USER_COMPLETED) {
    updateIntercom({ email, is_enterprise: isEnterprise });
  }
};
