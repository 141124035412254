import { Dialog } from '@material-ui/core';
import robodog from '@uppy/robodog';
import { flatMap } from 'lodash';
import log from 'loglevel';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import TransloaditAPI from '../../api/TransloaditAPI';
import UploadAPI from '../../api/UploadAPI';
import {
  TYPEFACE_UPLOAD_PROVIDERS,
  TYPEFACE_UPLOAD_RESTRICTIONS,
} from '../../constants/Constants';
import { trackUploads } from '../../events/sendEvents';
import useFonts from '../../hooks/useFonts';
import { selectOrganizationId } from '../../selectors/user';

export default function TypefaceUploadDialog({
  onClose = () => {},
  open = false,
}) {
  const dashboardRef = useRef(null);
  const organizationId = useSelector(selectOrganizationId);
  const { refetchUserFonts, refetchOrgFonts } = useFonts({ organizationId });

  useEffect(() => {
    const uploadResult = (result = {}, assemblyId) => {
      return new UploadAPI().postUpload({
        name: result.basename,
        mimetype: `font/${result.ext}`,
        size: result.size,
        filename: `${result.id}.${result.ext}`,
        encoder_id: assemblyId,
        is_logo: false,
        meta: result.meta,
      });
    };

    (async () => {
      const response = await new TransloaditAPI().getTemplateSignatureAuth(
        'font'
      );
      const { expires, key, signature, templateId, userId } = response;
      const dashboardContents = dashboardRef?.current?.children;
      if (!!dashboardContents && dashboardContents.length === 0) {
        robodog
          .dashboard('#robodog-dashboard', {
            params: {
              auth: { expires, key },
              template_id: templateId,
              fields: { userId },
            },
            providers: TYPEFACE_UPLOAD_PROVIDERS,
            restrictions: TYPEFACE_UPLOAD_RESTRICTIONS,
            signature,
            waitForEncoding: true,
            proudlyDisplayPoweredByUppy: false,
          })
          .on('transloadit:complete', async (assembly) => {
            const { assembly_id: assemblyId } = assembly;
            trackUploads(assembly.uploads, 'font');
            const results = flatMap(assembly.results);
            await Promise.all(
              results.map((result) => uploadResult(result, assemblyId))
            );
            refetchUserFonts();
            if (organizationId) {
              refetchOrgFonts();
            }
            onClose();
          })
          .on('error', (error) => {
            log.error('Uppy robodog error', { error });
          });
      }
    })();
  }, [onClose, organizationId, refetchUserFonts, refetchOrgFonts]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div id="robodog-dashboard" ref={dashboardRef} />
    </Dialog>
  );
}
