import { SvgIcon, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { VideoLibrary } from '@material-ui/icons';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import StylesIcon from '@videoblocks/react-icons/ColorBrush';
import FoldersIcon from '@videoblocks/react-icons/FolderStar';
import LogosIcon from '@videoblocks/react-icons/LayersBack';
import PaintingPalette from '@videoblocks/react-icons/PaintingPalette';
import StockIcon from '@videoblocks/react-icons/Search';
import TextIcon from '@videoblocks/react-icons/TextFormat';
import UploadsIcon from '@videoblocks/react-icons/UploadBottom';
import OverlaysIcon from '@videoblocks/react-icons/VideoEditPeel';
import { useDispatch, useSelector } from 'react-redux';

import { ASSET_RAIL_WIDTH } from '../../constants/Constants';
import { ASSET_RAIL } from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  DRAWERS,
  openAssetDrawer,
  selectBrandName,
  selectDrawer,
  selectIsBrandUnsaved,
  setIsBrandUnsaved,
} from '../../features/assetDrawer/assetDrawerSlice';
import { openBrandUnsavedDialog } from '../../features/ui/uiSlice';
import { selectIsEnterprise, selectIsOrgAdmin } from '../../selectors/user';

const useStyles = makeStyles((theme) => ({
  assetRail: {
    position: 'relative',
    zIndex: 1,
    flexShrink: 0,
    width: ASSET_RAIL_WIDTH,
    height: '100%',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
  },
  tab: {
    padding: theme.spacing(1.5, 1),
    minWidth: 'auto',
    minHeight: 'auto',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 16 / 14,
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeInOut,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  labelIcon: {
    '& $wrapper > *:first-child': {
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(16),
    },
  },
  wrapper: {}, // needed for `labelIcon` selector
}));

export default function AssetRailNav() {
  const dispatch = useDispatch();
  const drawer = useSelector(selectDrawer);
  const isEnterprise = useSelector(selectIsEnterprise);
  const isBrandUnsaved = useSelector(selectIsBrandUnsaved);
  const brandName = useSelector(selectBrandName);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const classes = useStyles();

  const tabs = [
    {
      label: 'Stock',
      icon: StockIcon,
      value: DRAWERS.STOCK,
    },
    {
      label: 'Uploads',
      icon: UploadsIcon,
      value: DRAWERS.UPLOADS,
    },
    isEnterprise
      ? {
          label: 'Brands',
          icon: PaintingPalette,
          value: DRAWERS.BRANDS,
        }
      : {
          label: 'Styles',
          icon: StylesIcon,
          value: DRAWERS.STYLES,
        },
    {
      label: 'Text',
      icon: TextIcon,
      value: DRAWERS.TEXT,
    },
    {
      label: 'Overlays',
      icon: OverlaysIcon,
      value: DRAWERS.OVERLAYS,
    },
    {
      label: 'Logos',
      icon: LogosIcon,
      value: DRAWERS.LOGOS,
    },
    {
      label: 'Folders',
      icon: FoldersIcon,
      value: DRAWERS.FOLDERS,
    },
    ...(isEnterprise
      ? [
          {
            label: 'Templates',
            icon: VideoLibrary,
            value: DRAWERS.TEMPLATES,
          },
        ]
      : []),
  ];

  const handleChange = (event, value) => {
    if (value === drawer) return;

    if (drawer === DRAWERS.BRANDS && isBrandUnsaved) {
      dispatch(
        openBrandUnsavedDialog({
          onConfirm: () => {
            changeDrawer(event, value);
            dispatch(setIsBrandUnsaved(false));
          },
          brandName,
        })
      );
    } else {
      changeDrawer(event, value);
    }
  };

  const changeDrawer = (event, drawer) => {
    const isLogosOrBrandsDrawer =
      drawer === DRAWERS.LOGOS || drawer === DRAWERS.BRANDS;
    const mediaType = isLogosOrBrandsDrawer ? MediaTypes.IMAGE : undefined;
    dispatch(openAssetDrawer({ drawer, mediaType }));
    trackEvent(SIDE_DRAWER.OPEN, { drawer, isOrgAdmin });
  };

  return (
    <Tabs
      className={classes.assetRail}
      variant="scrollable"
      scrollButtons="off"
      orientation="vertical"
      onChange={handleChange}
      value={drawer ? drawer : false}
      data-intercom-target={ASSET_RAIL}
      data-testid={ASSET_RAIL}
    >
      {tabs.map((tab) => (
        <Tab
          className={classes.tab}
          classes={{ labelIcon: classes.labelIcon, wrapper: classes.wrapper }}
          disabled={tab.disabled}
          icon={<SvgIcon component={tab.icon} />}
          label={tab.label}
          id={`${tab.value}-tab`}
          aria-controls={`${tab.value}-tabpanel`}
          value={tab.value}
          key={tab.value}
        />
      ))}
    </Tabs>
  );
}
