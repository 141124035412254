import { ButtonBase, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReactComponent as CloseTab } from '../../assets/shapes/tab.svg';
import {
  ASSET_DRAWER_WIDTH,
  ASSET_RAIL_WIDTH,
  NAVBAR_HEIGHT,
} from '../../constants/Constants';
import { ASSET_DRAWER } from '../../constants/TestSelectors';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  closeAssetDrawer,
  DRAWERS,
  selectAction,
  selectBrandName,
  selectDrawer,
  selectIsBrandUnsaved,
  selectOpen,
  setIsBrandUnsaved,
} from '../../features/assetDrawer/assetDrawerSlice';
import {
  openBrandUnsavedDialog,
  openTrimDialog,
} from '../../features/ui/uiSlice';
import useRenderer from '../../hooks/useRenderer';
import createObject from '../../models/createObject';
import {
  itemAdded,
  selectAllLayersWithItems,
} from '../../slices/storyboardSlice';
import { checkMediaItem } from '../../utils/mediaUtils';
import {
  getInsertLayerIndex,
  mapMediaToLayer,
} from '../../utils/storyboardUtils';
import TabPanel from '../TabPanel';
import AssetRailNav from './AssetRailNav';
import BrandsDrawer from './BrandsDrawer';
import FoldersDrawer from './FoldersDrawer';
import LogosDrawer from './LogosDrawer';
import OverlaysDrawer from './OverlaysDrawer';
import StockDrawer from './StockDrawer';
import StylesDrawer from './StylesDrawer';
import TemplatesDrawer from './TemplatesDrawer';
import TextDrawer from './TextDrawer';
import UploadsDrawer from './UploadsDrawer';

const useStyles = makeStyles((theme) => ({
  assetDrawer: {
    flexShrink: 0,
    width: ASSET_DRAWER_WIDTH,
  },
  drawerPaper: {
    top: NAVBAR_HEIGHT,
    height: `calc(100% - ${NAVBAR_HEIGHT}px)`,
  },
  drawerContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: ASSET_DRAWER_WIDTH - ASSET_RAIL_WIDTH,
    paddingRight: 4,
  },
  drawerOpen: {
    width: ASSET_DRAWER_WIDTH + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'visible',
    borderWidth: 0,
  },
  drawerClose: {
    overflowX: 'hidden',
    width: ASSET_RAIL_WIDTH + 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  closeTab: {
    fill: theme.palette.common.white,
  },
  leftChevron: {
    position: 'absolute',
  },
  closeButtonContainer: {
    position: 'absolute',
    zIndex: -1,
    top: '30%',
    right: 0,
    transform: 'translateX(calc(100% - 2px))',
  },
}));

export default function AssetDrawer() {
  const dispatch = useDispatch();
  const open = useSelector(selectOpen);
  const drawer = useSelector(selectDrawer);
  const action = useSelector(selectAction);
  const brandName = useSelector(selectBrandName);
  const isBrandUnsaved = useSelector(selectIsBrandUnsaved);
  const layersWithItems = useSelector(selectAllLayersWithItems);
  const { timestamp } = useRenderer();
  const { projectId, templateId } = useParams();
  const classes = useStyles();

  const handleMediaSelect = async (event, media) => {
    const item = await createObject(media);
    const { isAudio, isVideo } = checkMediaItem(item);

    if (isAudio || isVideo) {
      dispatch(openTrimDialog({ action, media: item, projectId, templateId }));
      trackEvent(SIDE_DRAWER.TRIM_MODAL_OPEN, item);
    } else {
      const layerIndex = getInsertLayerIndex(layersWithItems, {
        ...item,
        startTime: timestamp,
      });
      const toLayerIndex = Math.max(layerIndex, 0);

      dispatch(
        itemAdded({
          ...item,
          layerId: layersWithItems[layerIndex]?.id || nanoid(),
          layerIndex: toLayerIndex,
        })
      );
      trackEvent(SIDE_DRAWER.ITEM_ADD, {
        ...item,
        toLayerIndex,
        toLayerType: mapMediaToLayer(item.mediaType),
        newLayerCreated: layerIndex < 0,
      });
    }
  };

  const handleCloseSideDrawer = () => {
    if (drawer === DRAWERS.BRANDS && isBrandUnsaved) {
      handleCloseUnsavedBrand();
    } else {
      trackEvent(SIDE_DRAWER.CLOSE, { drawer });
      dispatch(closeAssetDrawer());
    }
  };

  const handleCloseUnsavedBrand = () => {
    dispatch(
      openBrandUnsavedDialog({
        onConfirm: () => {
          dispatch(closeAssetDrawer());
          dispatch(setIsBrandUnsaved(false));
        },
        brandName,
      })
    );
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.assetDrawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      data-testid={ASSET_DRAWER}
    >
      <AssetRailNav />
      <div className={classes.drawerContent}>
        <TabPanel index={DRAWERS.STOCK} value={drawer}>
          <StockDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.UPLOADS} value={drawer}>
          <UploadsDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.BRANDS} value={drawer}>
          <BrandsDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.STYLES} value={drawer}>
          <StylesDrawer />
        </TabPanel>
        <TabPanel index={DRAWERS.TEXT} value={drawer}>
          <TextDrawer />
        </TabPanel>
        <TabPanel index={DRAWERS.OVERLAYS} value={drawer}>
          <OverlaysDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.LOGOS} value={drawer}>
          <LogosDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.FOLDERS} value={drawer}>
          <FoldersDrawer onSelect={handleMediaSelect} />
        </TabPanel>
        <TabPanel index={DRAWERS.TEMPLATES} value={drawer}>
          <TemplatesDrawer />
        </TabPanel>
      </div>
      <ButtonBase
        className={classes.closeButtonContainer}
        onClick={handleCloseSideDrawer}
        aria-label="Close"
      >
        <CloseTab className={classes.closeTab} />
        <ChevronLeftIcon className={classes.leftChevron} />
      </ButtonBase>
    </Drawer>
  );
}
