export const ASSET_DRAWER = 'asset-drawer';
export const ASSET_RAIL = 'asset-rail';
export const AUDIO_THUMB_FALLBACK = 'audio-thumb-fallback';
export const BASIC_COLOR_PICKER_SECTION = 'basic-color-picker-section';
export const BRAND_CARD_OPTIONS = 'brand-card-options';
export const BRAND_COLOR_PICKER_SECTION = 'brand-color-picker-section';
export const COLOR_PREVIEW = 'color-preview';
export const EXPORT_BUTTON = 'export-button';
export const FOLDER_PREVIEW = 'folder-preview';
export const FOLDER_PREVIEW_THUMBNAIL = 'folder-preview-thumbnail';
export const FONT_PREVIEW_HEADER = 'font-preview-header';
export const FONT_PREVIEW_BODY = 'font-preview-body';
export const GRID_CELL = 'grid-cell';
export const GRID_COL = 'grid-col';
export const MEDIA_PREVIEW = 'media-preview';
export const PLAYBACK_CONTROLS = 'playback-controls';
export const PLAY_PAUSE_BUTTON = 'play-pause-button';
export const TEAM_ACCESS_SWITCH = 'team-access';
export const TIMELINE_CONTAINER = 'timeline-container';
export const TIMELINE_ITEM = 'timeline-item';
export const TIMELINE_LAYER = 'timeline-layer';
export const TIMELINE_LAYER_CONTAINER = 'timeline-layer-container';
export const TRIM_ADD_CLIP = 'trim-add-clip';
export const TRIM_DIALOG = 'trim-dialog';
export const TRIM_DRAG_HANDLE_LEFT = 'trim-drag-handle-left';
export const TRIM_DRAG_HANDLE_RIGHT = 'trim-drag-handle-right';
export const VIDEO_LIBRARY_ICON = 'video-library-icon';
export const VIDEO_PREVIEW = 'video-preview';
export const VIDEO_THUMBNAIL = 'video-thumbnail';
export const VIDEO_PREVIEW_CONTAINER = 'video-preview-container';
export const YOUTUBE_CARD = 'youtube-card';
