import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ColorGrid from './ColorGrid';
import { useRecentColors } from './useRecentColors';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0.5, 1, 0.5, 0),
  },
}));

export default function RecentPicker({ onChange }) {
  const classes = useStyles();
  const { recentColors } = useRecentColors();

  return recentColors.length > 0 ? (
    <>
      <Typography className={classes.label}>Recent</Typography>
      <ColorGrid onChange={onChange} colors={recentColors} />
    </>
  ) : null;
}

RecentPicker.propTypes = {
  onChange: PropTypes.func,
  recentColors: PropTypes.arrayOf(PropTypes.string),
};
