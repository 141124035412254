import { isEmpty } from 'lodash';

import fonts from '../constants/Fonts';
import { standardizeFont } from '../utils/fonts';
import useUploads from './useUploads';

export default function useFonts({ organizationId }) {
  const {
    data: userFontsData,
    isLoadingMore: isLoadingUserFonts,
    deleteUpload: deleteUserFont,
    refetch: refetchUserFonts,
  } = useUploads('font');

  const {
    data: orgFontsData,
    isLoadingMore: isLoadingOrgFonts,
    associateUpload,
    disassociateUpload,
    refetch: refetchOrgFonts,
  } = useUploads('font', {
    organization: organizationId,
  });

  const userFonts = userFontsData.map(standardizeFont).filter((font) => !!font);
  const hasUserFonts = !isLoadingUserFonts && !isEmpty(userFonts);

  const orgFonts = orgFontsData.map(standardizeFont).filter((font) => !!font);
  const hasOrgFonts = !isLoadingOrgFonts && !isEmpty(orgFonts);

  const allFonts = fonts.concat(userFonts, orgFonts);

  return {
    userFonts,
    hasUserFonts,
    deleteUserFont,
    refetchUserFonts,
    orgFonts,
    hasOrgFonts,
    associateUpload,
    disassociateUpload,
    refetchOrgFonts,
    allFonts,
  };
}
