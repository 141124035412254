import { levels } from 'loglevel';
import { useEffect } from 'react';

import { IS_PROD } from '../constants/Constants';
import flags from '../features/flags/flags';
import useFlag from '../features/flags/useFlag';
import analyticsErrorTransport from './analyticsErrorTransport';
import logger from './logger';
import sentryTransport from './sentryTransport';

if (IS_PROD) logger.addTransport(analyticsErrorTransport, levels.ERROR);

logger.addTransport(sentryTransport, levels.ERROR);

const defaultLevel = IS_PROD ? levels.SILENT : levels.WARN;

export default function LoggerLoader() {
  const isDebug = useFlag(flags.DEBUG);

  useEffect(() => {
    logger.consoleLevel = isDebug ? levels.DEBUG : defaultLevel;
  }, [isDebug]);

  return null;
}
