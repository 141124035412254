import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  ListSubheader,
  Popover,
  SvgIcon,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronDown, ChevronUp } from '@videoblocks/react-icons';
import { isEmpty } from 'lodash';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import fonts, { FALLBACK_FONT } from '../constants/Fonts';
import { trackUploadDelete } from '../events/sendEvents';
import {
  openConfirmationDialog,
  openTypefaceUploadDialog,
} from '../features/ui/uiSlice';
import useFonts from '../hooks/useFonts';
import {
  selectIsEnterprise,
  selectIsOrgAdmin,
  selectOrganizationId,
} from '../selectors/user';
import {
  addRecentFont,
  removeRecentFont,
  selectRecentFonts,
} from '../slices/editorSlice';
import { fontRemoved } from '../slices/storyboardSlice';
import {
  getFontByLookupId,
  getLookupIdByFont,
  getStylePropertiesFromFont,
  getUniqueFonts,
  groupFontsByFamily,
} from '../utils/fonts';
import DeleteIconButton from './DeleteIconButton';

const useStyles = makeStyles((theme) => ({
  button: {
    width: '100%',
    color: theme.palette.grey[800],
    backgroundColor: theme.palette.common.white,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[800],
    lineHeight: '1rem',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[800],
    },
  },
  buttonWrapper: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  deleteButton: {
    opacity: 0,
    color: theme.palette.grey[700],
    padding: theme.spacing(0.5),
    '&:hover': {
      color: theme.palette.grey[900],
    },
  },
  deleteWrapper: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    maxHeight: '80%',
  },
  disabled: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  endIcon: {
    color: theme.palette.grey[400],
    marginLeft: 'auto',
    '& > *:first-child': {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  fontFamilyHeader: {
    maxHeight: theme.spacing(5),
    padding: theme.spacing(0, 2, 0, 1),
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&.Mui-expanded': {
      minHeight: theme.spacing(6),
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 'auto',
    },
  },
  fontFamily: {
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  fontWrapper: {
    padding: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  input: {
    padding: theme.spacing(0.875, 2),
    fontWeight: theme.typography.fontWeightRegular,
    borderWidth: 1,
    borderColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  menuDiv: {
    maxHeight: '50vh',
    padding: theme.spacing(1),
  },
  menuItem: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: theme.spacing(5),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
      '& $deleteButton': {
        opacity: 1,
      },
    },
  },
  selectedFont: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  subheader: {
    textTransform: 'uppercase',
  },
}));

export default function FontSelectGrouped({
  disabled = false,
  onChange = () => {},
  value = FALLBACK_FONT,
  id,
  brandUid,
  isBrandsForm = false,
}) {
  const organizationId = useSelector(selectOrganizationId);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const isEnterprise = useSelector(selectIsEnterprise);
  const {
    userFonts,
    hasUserFonts,
    deleteUserFont,
    orgFonts,
    hasOrgFonts,
    associateUpload,
    disassociateUpload,
  } = useFonts({ organizationId });

  const classes = useStyles();
  const dispatch = useDispatch();

  const recentFonts = useSelector(selectRecentFonts) ?? [];
  const hasRecentFonts = !isEmpty(recentFonts);

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'font-select-popover',
  });
  const buttonRef = useRef();

  const handleFontSelect = (font) => {
    const lookupId = getLookupIdByFont(font);

    // Classify the font
    const matchingBrandFont = getFontByLookupId(orgFonts, lookupId);
    const matchingRecentFont = getFontByLookupId(recentFonts, lookupId);
    const matchingCustomFont = !!font.fileId;

    // Update the recent fonts list
    if (!matchingRecentFont) {
      dispatch(addRecentFont(font));
    }

    // If in brand form, associate the custom font with the brand
    if (isBrandsForm && brandUid && matchingCustomFont) {
      associateUpload({
        uploadId: font.uploadId,
        brandUid,
      });
    }

    onChange(
      font,
      !!matchingBrandFont,
      !!matchingRecentFont,
      !!matchingCustomFont
    );
    popupState.close();
  };

  const handleUploadClick = () => {
    dispatch(openTypefaceUploadDialog());
  };

  const handleDeleteBrandFont = (font) => {
    if (isOrgAdmin) {
      disassociateUpload({ uploadId: font.uploadId, brandUid });
    }
  };

  const handleUploadDelete = async (upload, event, deleteAsync) => {
    event.stopPropagation();

    dispatch(
      openConfirmationDialog({
        title: 'Delete Typeface?',
        text: (
          <>
            It will be replaced with the default typeface in all projects{' '}
            {isEnterprise ? 'and templates ' : ''}that use it.
          </>
        ),
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirm: async () => {
          if (deleteAsync) {
            await deleteAsync(upload.uploadId);
          }
          dispatch(removeRecentFont(upload));
          dispatch(fontRemoved(upload.name));
          trackUploadDelete(upload);
          // font fallback is handled by useEffect in PreviewContainer
        },
      })
    );
  };

  const renderGroupedFonts = (fonts, keyPrefix, deleteAsync) => {
    const groupedFonts = groupFontsByFamily(fonts);
    return Object.entries(groupedFonts).map(([family, familyFonts]) => {
      return renderFontFamily(family, familyFonts, keyPrefix, deleteAsync);
    });
  };

  const renderFontFamily = (family, fonts, keyPrefix, deleteAsync) => {
    if (fonts.length > 1) {
      return (
        <Accordion style={{ boxShadow: 'none' }} key={`${keyPrefix}-${family}`}>
          <AccordionSummary className={classes.fontFamilyHeader}>
            <Typography style={getStylePropertiesFromFont(fonts[0])}>
              {family}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.fontFamily}>
            {fonts.map((font) =>
              renderFont(font, keyPrefix, deleteAsync, true)
            )}
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return renderFont(fonts[0], keyPrefix, deleteAsync);
    }
  };

  const renderFont = (
    font,
    keyPrefix,
    deleteAsync,
    useFontStyleAsName = false
  ) => {
    return (
      <div
        className={classes.menuItem}
        onClick={() => handleFontSelect(font)}
        key={`${keyPrefix}-${getLookupIdByFont(font)}-${font.weight}`}
        role="button"
      >
        <span className={classes.fontWrapper}>
          <Typography style={getStylePropertiesFromFont(font)}>
            {useFontStyleAsName && font.meta?.style
              ? font.meta?.style
              : font.name}
          </Typography>
        </span>
        {deleteAsync && (
          <div className={classes.deleteWrapper}>
            <DeleteIconButton
              aria-label="delete font"
              edge="end"
              className={classes.deleteButton}
              onClick={(event) => {
                handleUploadDelete(font, event, deleteAsync);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <Box id={id}>
      <Button
        disabled={disabled}
        className={classes.input}
        classes={{ disabled: classes.disabled, endIcon: classes.endIcon }}
        variant="outlined"
        ref={buttonRef}
        fullWidth
        endIcon={
          <SvgIcon
            fontSize="small"
            className={classes.endIcon}
            component={popupState.isOpen ? ChevronUp : ChevronDown}
          />
        }
        {...bindTrigger(popupState)}
      >
        <Typography
          style={getStylePropertiesFromFont(value)}
          className={classes.selectedFont}
        >
          {value.name}
        </Typography>
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            width: buttonRef.current
              ? buttonRef.current.offsetWidth
              : 'inherit',
          },
        }}
      >
        <div className={classes.menuDiv}>
          {/* Upload Button */}
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              variant="contained"
              onClick={handleUploadClick}
            >
              Upload Typeface
            </Button>
          </div>
          {/* User Fonts */}
          {hasUserFonts && (
            <>
              <ListSubheader
                className={classes.subheader}
                disableSticky
                key="my-typefaces"
              >
                My Typefaces
              </ListSubheader>
              {renderGroupedFonts(userFonts, 'user', deleteUserFont)}
              <Box m={1} key="my-typefaces-divider">
                <Divider />
              </Box>
            </>
          )}
          {/* Recent Fonts */}
          {hasRecentFonts && !isBrandsForm && (
            <>
              <ListSubheader
                className={classes.subheader}
                disableSticky
                key="recents"
              >
                Recent
              </ListSubheader>
              {renderGroupedFonts(recentFonts, 'recent')}
              <Box m={1} key="recents-divider">
                <Divider />
              </Box>
            </>
          )}
          {/* Org Fonts */}
          {isEnterprise && hasOrgFonts && (
            <>
              <ListSubheader
                className={classes.subheader}
                disableSticky
                key="brands"
              >
                From Brands
              </ListSubheader>
              {renderGroupedFonts(
                getUniqueFonts(orgFonts),
                'brand',
                handleDeleteBrandFont
              )}
              <Box m={1} key="brands-divider">
                <Divider />
              </Box>
            </>
          )}
          <ListSubheader className={classes.subheader} disableSticky>
            All Typefaces
          </ListSubheader>
          {renderGroupedFonts(fonts, 'default')}
        </div>
      </Popover>
    </Box>
  );
}
