/* global Intercom */
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BugReport from '@material-ui/icons/BugReport';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { dsn } from '../config/sentry';
import { selectEmail, selectFullName } from '../selectors/user';
import { selectWorkspaceProjectId } from '../selectors/workspace';
import { selectStoryboard } from '../slices/storyboardSlice';

const useStyles = makeStyles((theme) => ({
  iconButton: (props) => ({
    position: 'fixed',
    zIndex: 1,
    bottom: theme.spacing(2.5),
    right: theme.spacing(2.5),
    padding: theme.spacing(2.25),
    backgroundColor: theme.palette.grey[200],
    opacity: props.isShown ? 1 : 0,
    transform: props.isShown ? `translateX(-120%)` : `translateX(0)`,
    transition: theme.transitions.create(['transform', 'opacity'], {
      duration: theme.transitions.duration.complex,
    }),
    '&:hover': {
      backgroundColor: theme.palette.grey[400],
    },
  }),
}));

export default function BugReportButton() {
  const [isShown, setIsShown] = useState(false);

  const classes = useStyles({ isShown });

  const email = useSelector(selectEmail);
  const name = useSelector(selectFullName);
  const selectedProjectId = useSelector(selectWorkspaceProjectId);
  const project = useSelector(selectStoryboard);

  useEffect(() => {
    Intercom('onHide', () => {
      setIsShown(false);
    });
    Intercom('onShow', () => {
      setIsShown(true);
    });
  }, []);

  return (
    <Tooltip title="Report a Bug" placement="left">
      <IconButton
        className={classes.iconButton}
        variant="contained"
        color="secondary"
        onClick={() => {
          Intercom('hide');
          Sentry.withScope((scope) => {
            scope.setExtras({
              // Add extra info here as needed
              selectedProjectId,
              project: JSON.stringify(project),
            });
            Sentry.captureException(new Error('Manual Bug Report'));
          });
          Sentry.showReportDialog({
            user: {
              name,
              email,
            },
            labelSubmit: 'Submit Bug Report',
            dsn,
          });
        }}
      >
        <BugReport />
      </IconButton>
    </Tooltip>
  );
}
