import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import fonts from '../constants/Fonts';
import useFonts from '../hooks/useFonts';
import { selectOrganizationId } from '../selectors/user';
import { loadGoogleFonts, loadUploadedFonts } from '../utils/fonts';

/**
 * Preload fonts so they're ready before the user opens the Font Select.
 */
function FontLoader() {
  useEffect(() => loadGoogleFonts(fonts), []);

  const organizationId = useSelector(selectOrganizationId);
  const { userFonts, orgFonts } = useFonts({ organizationId });

  useEffect(() => loadUploadedFonts(userFonts), [userFonts]);
  useEffect(() => loadUploadedFonts(orgFonts), [orgFonts]);

  return null;
}

export default FontLoader;
