import { get } from 'lodash';

import toBool from '../utils/toBool';

/**
 * Environment Constants
 */

export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'dev';

export const CONTEXT = get(
  {
    production: PRODUCTION,
    'deploy-preview': STAGING,
    'branch-deploy': STAGING,
    'ci-blocks': STAGING,
  },
  process.env.REACT_APP_CONTEXT,
  DEVELOPMENT
);

export const BACKEND_CONTEXT = process.env.REACT_APP_BACKEND_CONTEXT || CONTEXT;

export const IS_PROD = CONTEXT === PRODUCTION;
export const IS_STAGING = CONTEXT === STAGING;
export const IS_DEV = CONTEXT === DEVELOPMENT;

/**
 * Search Constants
 */

export const SEARCH_RESULTS_PAGE_SIZE = 20; // results per page

/**
 * Event Constants
 */

export const KAFKA_EVENTS_ENABLED = toBool(
  process.env.REACT_APP_KAFKA_EVENTS_ENABLED
);

/**
 * Upload Constants
 */

export const FILE_UPLOAD_SIZE_MAX = 250 * 1024 * 1024; // 250 MB
export const FILE_UPLOAD_NUMBER_MAX = 10;
export const MEDIA_UPLOAD_PROVIDERS = [
  'facebook',
  'instagram',
  'dropbox',
  'google-drive',
];
export const MEDIA_UPLOAD_FILE_TYPES = ['audio/*', 'image/*', 'video/*'];
export const LOGO_UPLOAD_FILE_TYPES = ['image/*'];
export const TYPEFACE_UPLOAD_PROVIDERS = ['dropbox', 'google-drive'];
export const TYPEFACE_UPLOAD_FILE_TYPES = ['.ttf', '.otf'];
export const TYPEFACE_UPLOAD_RESTRICTIONS = {
  allowedFileTypes: TYPEFACE_UPLOAD_FILE_TYPES,
  maxFileSize: FILE_UPLOAD_SIZE_MAX,
  maxNumberOfFiles: FILE_UPLOAD_NUMBER_MAX,
};
export const SOURCE_TYPES = {
  STOCK: 'stock',
  UPLOAD: 'upload',
};

/**
 * UI Constants
 */

export const NAVBAR_HEIGHT = 56;
export const UNIFIED_NAVBAR_HEIGHT = 80;
export const ASSET_RAIL_WIDTH = 80;
export const ASSET_DRAWER_WIDTH = 480;
export const EXPORT_DRAWER_WIDTH = 480;

// 40px height + 16px top & bottom padding
export const PLAYBACK_CONTROLS_HEIGHT = 16 + 40 + 16;

export const PREVIEW_TOP_MARGIN = 16;
export const PREVIEW_WIDTH_MARGIN = 16;
export const PREVIEW_MIN_WIDTH = 200;
export const PREVIEW_MIN_HEIGHT = 200;

export const MIN_PLAYBACK_CONTROLS_WIDTH = 240;

export const PROPERTIES_PANEL_WIDTH = 270;

export const STAGE_MIN_HEIGHT =
  PREVIEW_TOP_MARGIN + PREVIEW_MIN_HEIGHT + PLAYBACK_CONTROLS_HEIGHT;

export const TIMELINE_MIN_HEIGHT = 64;
export const TIMELINE_DEFAULT_HEIGHT = 272;
export const TIMELINE_TOOLBAR_HEIGHT = 32;
export const TIMELINE_LEFT_PADDING = 16;
export const TIMELINE_TOP_PADDING = 16;

export const LAYER_HEIGHT = 32;
export const LAYER_TALL_HEIGHT = 54;

export const OFF_SCREEN = -9999;

export const RECENT_COLOR_LIMIT = 7;

// distance in pixels that a Draggable item must be moved before a drag begins
export const DND_MIN_DISTANCE = 5;
export const DND_ZONES = {
  CANVAS: 'CANVAS',
  DRAWER: 'DRAWER',
  TIMELINE: 'TIMELINE',
};

export const SIDE_NAV_WIDTH = 240;
