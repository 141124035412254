import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  SvgIcon,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import Bin from '@videoblocks/react-icons/Bin';
import QuestionCircleIcon from '@videoblocks/react-icons/QuestionCircle';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { stringify } from 'query-string';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import tinycolor from 'tinycolor2';

import { SOURCE_TYPES } from '../constants/Constants';
import {
  DEFAULT_BODY_FONT,
  DEFAULT_HEADER_FONT,
  TEXT_VARIANT,
} from '../constants/FontStyles';
import {
  DEFAULT_BRAND_NAME,
  DEFAULT_BRAND_PRIMARY_COLOR,
  DEFAULT_BRAND_SECONDARY_COLOR,
  DEFAULT_COLOR_OVERLAY_TINT,
  MAX_BRAND_COLORS,
} from '../constants/StylesConstants';
import { BRANDS_PATH } from '../constants/Urls';
import {
  trackApplyStylesClick,
  trackEvent,
  trackSetAsDefaultStylesClick,
  trackStylesImageColorOverlay,
  trackStylesPrimaryTextColor,
  trackStylesSecondaryTextColor,
  trackStylesTypographyChange,
  trackStylesVideoColorOverlay,
  trackUploadDelete,
} from '../events/sendEvents';
import { BRANDS } from '../events/tags';
import {
  selectBrandUid,
  selectIsBrandUnsaved,
  setBrandName,
  setBrandUid,
  setIsBrandUnsaved,
} from '../features/assetDrawer/assetDrawerSlice';
import {
  closeDialog,
  openBrandUnsavedDialog,
  openBrandUpdateDialog,
  openConfirmationDialog,
  openToast,
  openUploadDialog,
} from '../features/ui/uiSlice';
import useBrand from '../hooks/useBrand';
import useBrands from '../hooks/useBrands';
import useQuery from '../hooks/useQuery';
import useUploads from '../hooks/useUploads';
import {
  selectIsEnterprise,
  selectIsOrgAdmin,
  selectOrganizationId,
} from '../selectors/user';
import {
  selectStyles,
  stylesApplied,
  stylesUpdated,
} from '../slices/storyboardSlice';
import theme from '../styles/theme';
import toUpperHexString from '../utils/toUpperHexString';
import LoaderContainer from './LoaderContainer';
import StylesFormButtons from './StylesFormButtons';
import StylesFormColorEditor from './StylesFormColorEditor';
import Toast from './Toast';
import UploadPreview from './UploadPreview';
import AddColorButton from './assetDrawer/AddColorButton';
import FontPreview from './assetDrawer/FontPreview';
import ResultsGrid from './assetDrawer/ResultsGrid';
import UploadButton from './assetDrawer/UploadButton';
import ColorGrid from './colorPicker/ColorGrid';
import ColorPickerPopover from './colorPicker/ColorPickerPopover';

const useStyles = makeStyles((theme) => ({
  brandNameView: {
    color: theme.palette.grey[900],
    marginRight: theme.spacing(2),
    overflowWrap: 'anywhere',
  },
  brandNameActionContainer: {
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    direction: 'row',
  },
  colorDeleteIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    fill: theme.palette.grey[500],
  },
  colorDeleteIconDisabled: {
    fill: theme.palette.grey[200],
  },
  colorPickerListItem: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  colorPickerListItemValue: {
    display: 'inline-block',
    width: '100%',
  },
  colorPickerListItemDelete: {
    display: 'inline-block',
    width: theme.spacing(4),
    minWidth: theme.spacing(4), // Important for alignment in the drawer
    textAlign: 'right',
  },
  defaultBrandText: {
    backgroundColor: theme.palette.blue[100],
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    alignSelf: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  disabledInput: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.common.black,
  },
  emptyLogosContainer: {
    width: '100%',
    display: 'flex',
    justify: 'center',
    textAlign: 'center',
  },
  emptyLogosText: {
    color: theme.palette.grey[600],
    fontWeight: theme.typography.fontWeightBold,
    width: '100%',
    alignSelf: 'center',
  },
  infoTooltip: {
    color: theme.palette.grey[300],
    fontSize: theme.typography.pxToRem(17),
    marginLeft: theme.spacing(1),
  },
  inputLabel: {
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.grey[800],
  },
  inputLabelGroup: {
    display: 'flex',
  },
  logosCount: {
    marginBottom: theme.spacing(2),
  },
  swatchLabel: {
    color: theme.palette.grey[700],
    marginLeft: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
  },
  viewOnlySwatch: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '9999px',
    minWidth: theme.typography.pxToRem(24),
    aspectRatio: 1,
    padding: 0,
  },
}));

export default function StylesForm({
  appliedBrandUid = null,
  disabled = false,
  isBrandForm = false, // Display brand fields
  isDrawer = false, // Toggles between brand drawer and detail page form
  isNewBrand = false,
  navigateToBrand = () => {},
  onApply: onApplyBrand = () => {},
  onDelete: onDeleteBrand = () => {},
  onLogoSelect = () => {},
  onSaveNewBrand = () => {},
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const unblockHandle = useRef();
  const { projectId, templateId } = useParams();
  const { flags, isEditing } = useQuery();

  const editMode = !!isEditing || isNewBrand;
  const isOrgAdmin = useSelector(selectIsOrgAdmin);
  const orgId = useSelector(selectOrganizationId);
  const brandUid = useSelector(selectBrandUid);
  const currentStyles = useSelector(selectStyles);
  const isBrandUnsaved = useSelector(selectIsBrandUnsaved);
  const isEnterprise = useSelector(selectIsEnterprise);

  const { brands, deleteBrand } = useBrands();
  const { brand, isLoading, editBrand } = useBrand(brandUid);

  const {
    data: logos,
    disassociateUpload,
    associateUpload,
    updateUpload,
    refetch,
  } = useUploads(MediaTypes.IMAGE, {
    organization: orgId,
    brandUid,
    isLogo: true,
  });

  const initialValues = isBrandForm ? {} : currentStyles;

  // Define form fields
  const [name, setName] = useState(initialValues?.name ?? DEFAULT_BRAND_NAME);
  const [headerFont, setHeaderFont] = useState(
    initialValues?.fonts?.header ?? initialValues?.font ?? DEFAULT_HEADER_FONT
  );
  const [bodyFont, setBodyFont] = useState(
    initialValues?.fonts?.body ?? initialValues?.font ?? DEFAULT_BODY_FONT
  );

  const [primaryTextColor, setPrimaryTextColor] = useState(
    initialValues?.primaryTextColor ??
      (isBrandForm
        ? DEFAULT_BRAND_PRIMARY_COLOR
        : DEFAULT_HEADER_FONT.primaryColor)
  );
  const [secondaryTextColor, setSecondaryTextColor] = useState(
    initialValues?.secondaryTextColor ??
      (isBrandForm
        ? DEFAULT_BRAND_SECONDARY_COLOR
        : DEFAULT_HEADER_FONT.secondaryColor)
  );
  const [colors, setColors] = useState([primaryTextColor, secondaryTextColor]);
  const [videoTint, setVideoTint] = useState(
    initialValues?.videoTint ?? DEFAULT_COLOR_OVERLAY_TINT
  );
  const [imageTint, setImageTint] = useState(
    initialValues?.imageTint ?? DEFAULT_COLOR_OVERLAY_TINT
  );

  const styles = {
    fonts: {
      header: headerFont,
      body: bodyFont,
    },
    primaryTextColor,
    secondaryTextColor,
    colors,
    videoTint,
    imageTint,
  };

  useEffect(() => {
    // Set brand name initially and on change for unsaved dialogue
    dispatch(setBrandName(isEmpty(name) ? DEFAULT_BRAND_NAME : name));
  }, [dispatch, name]);

  // When the brand API request completes, update the form
  useEffect(() => {
    if (isBrandForm && !isNewBrand && !!brand?.detail?.styles && !isLoading) {
      const name = brand.detail.name;
      const {
        font,
        fonts,
        primaryTextColor,
        secondaryTextColor,
        colors,
        videoTint,
        imageTint,
      } = brand?.detail.styles;
      setName(name);
      setHeaderFont(fonts?.header ?? font);
      setBodyFont(fonts?.body ?? font);
      setPrimaryTextColor(primaryTextColor);
      setSecondaryTextColor(secondaryTextColor);
      setColors(colors ?? [primaryTextColor, secondaryTextColor]);
      setVideoTint(videoTint);
      setImageTint(imageTint);
    }
  }, [brand?.detail, dispatch, isBrandForm, isLoading, isNewBrand]);

  // Prompt user to save before navigating away
  useEffect(() => {
    unblockHandle.current = history.block((targetLocation) => {
      if (isBrandForm && isBrandUnsaved) {
        const targetUrl = `${targetLocation.pathname}${targetLocation.search}`;
        dispatch(
          openBrandUnsavedDialog({
            onConfirm: () => {
              if (isNewBrand) {
                deleteBrand(brandUid);
              }
              dispatch(setIsBrandUnsaved(false));
              if (unblockHandle) {
                unblockHandle.current();
              }
              // drop isEditing query from search
              history.push({
                pathname: targetUrl,
                search: stringify({ flags }),
              });
            },
            brandName: brand?.detail?.name || DEFAULT_BRAND_NAME,
          })
        );
        return false;
      }
    });
    return () => {
      unblockHandle.current && unblockHandle.current();
    };
  }, [
    brandUid,
    deleteBrand,
    dispatch,
    flags,
    history,
    isBrandForm,
    isBrandUnsaved,
    isNewBrand,
    brand?.detail?.name,
  ]);

  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastSeverity, setToastSeverity] = useState('success');

  const closeToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  const gridSpacing = 5;

  const setBrandUnsaved = (isUnsaved) => {
    if (isBrandForm) {
      return dispatch(setIsBrandUnsaved(isUnsaved));
    }
    return Promise.resolve();
  };

  // Define handlers
  const handleNameChange = ({ target: { value } }) => {
    setBrandUnsaved(true);
    setName(value);
  };

  const handleTypographyChange = (type, font) => {
    setBrandUnsaved(true);
    trackStylesTypographyChange(font);
    type === TEXT_VARIANT.HEADER ? setHeaderFont(font) : setBodyFont(font);
  };

  const handleColorAdd = () => {
    setBrandUnsaved(true);
    setColors(colors.concat(tinycolor(theme.palette.common.white).toRgb()));
  };

  const handleColorChange = (idx, color) => {
    const newColors = Array.from(colors);
    newColors.splice(idx, 1, color);
    setColors(newColors);
    setBrandUnsaved(true);
  };

  const handleTextColorChange = (color) => {
    setBrandUnsaved(true);
    trackStylesPrimaryTextColor(color);
    setPrimaryTextColor(color);
  };

  const handleAccentColorChange = (color) => {
    setBrandUnsaved(true);
    trackStylesSecondaryTextColor(color);
    setSecondaryTextColor(color);
  };

  const handleColorDelete = (idx) => {
    setBrandUnsaved(true);
    const newColors = Array.from(colors);
    const oldColor = newColors.splice(idx, 1)[0];
    if (tinycolor.equals(oldColor, primaryTextColor)) {
      setPrimaryTextColor(
        newColors.find((c) => !tinycolor.equals(c, secondaryTextColor))
      );
    } else if (tinycolor.equals(oldColor, secondaryTextColor)) {
      setSecondaryTextColor(
        newColors.find((c) => !tinycolor.equals(c, primaryTextColor))
      );
    }
    setColors(newColors);
  };

  const handleVideoColorOverlayChange = (color) => {
    setBrandUnsaved(true);
    trackStylesVideoColorOverlay(color);
    setVideoTint(color);
  };

  const handleImageColorOverlayChange = (color) => {
    setBrandUnsaved(true);
    trackStylesImageColorOverlay(color);
    setImageTint(color);
  };

  const handleCreateNewBrand = async () => {
    const newName = isEmpty(name) ? DEFAULT_BRAND_NAME : name;

    await setBrandUnsaved(false); // make sure to do this before the swr call so the Unsaved Changes modal doesn't fire
    editBrand({
      newDetail: { name: newName, styles },
      isDraft: false,
    });

    trackEvent(BRANDS.CONFIRM_CREATE, {
      first: isEmpty(brands),
      brandName: newName,
      font: styles.fonts ?? styles.font,
      primaryTextColor: tinycolor(styles.primaryTextColor).toRgbString(),
      secondaryTextColor: tinycolor(styles.secondaryTextColor).toRgbString(),
      totalColors: styles.colors?.length,
      videoTint: tinycolor(styles.videoTint).toRgbString(),
      imageTint: tinycolor(styles.imageTint).toRgbString(),
      logos: logos?.length,
    });

    if (isDrawer) {
      dispatch(setBrandUid(brandUid));
      // drop isEditing query from search
      history.replace({
        search: stringify({ flags }),
      });
      onSaveNewBrand();
    } else {
      history.push({
        pathname: `${BRANDS_PATH}/${brandUid}`,
        search,
      });
    }
    setToastText(`"${newName}" was created`);
    setShowToast(true);
  };

  const handleSaveBrand = () => {
    const newName = isEmpty(name) ? DEFAULT_BRAND_NAME : name;
    const updateBrand = async () => {
      editBrand({
        newDetail: { name: newName, styles },
      });
      await setBrandUnsaved(false);
      setToastSeverity('success');
      setToastText(`"${newName}" changes saved`);
      setShowToast(true);
      navigateToBrand(brandUid);
    };

    if (brand.projects_count > 0 || brand.templates_count > 0) {
      dispatch(
        openBrandUpdateDialog({
          projectsCount: brand.projects_count,
          templatesCount: brand.templates_count,
          onConfirm: () => {
            updateBrand();
          },
        })
      );
    } else {
      updateBrand();
    }

    trackEvent(BRANDS.SAVE, {
      first: isEmpty(brands),
      brandName: newName,
      font: styles.fonts ?? styles.font,
      primaryTextColor: tinycolor(styles.primaryTextColor).toRgbString(),
      secondaryTextColor: tinycolor(styles.secondaryTextColor).toRgbString(),
      totalColors: styles.colors?.length,
      videoTint: tinycolor(styles.videoTint).toRgbString(),
      imageTint: tinycolor(styles.imageTint).toRgbString(),
      logos: logos?.length,
      numProjectsAffected: brand.projects_count,
      brandUid,
    });
  };

  const handleApplyStyle = () => {
    dispatch(
      openConfirmationDialog({
        title: 'Apply Styles?',
        text: (
          <>This will re-style current and future elements in your project.</>
        ),
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirm: () => {
          dispatch(stylesApplied(styles));
          dispatch(closeDialog());
        },
      })
    );
    trackApplyStylesClick({ styles });
  };

  const handleSetStyleAsDefault = () => {
    dispatch(stylesUpdated(styles));
    trackSetAsDefaultStylesClick({ styles });
    dispatch(openToast('Default styles have been set'));
  };

  const handleLogoDelete = async (event, upload) => {
    dispatch(
      openConfirmationDialog({
        title: 'Delete Logo?',
        text: (
          <>
            It will no longer be available to members of your organization as
            part of this brand.
          </>
        ),
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirm: async () => {
          await disassociateUpload({ uploadId: upload.id, brandUid });
          trackUploadDelete(upload);
        },
      })
    );
  };

  const handleLogoSelect = async (event, logo) => {
    onLogoSelect(null, {
      ...logo,
      mediaType: MediaTypes.IMAGE,
      sourceType: SOURCE_TYPES.UPLOAD,
      isForeground: true,
      isLogo: true,
      scale: 0.2,
    });
    await associateUpload({
      uploadId: logo.id,
      projectUid: projectId,
      templateUid: templateId,
    });
  };

  const handleUploadError = (message) => {
    setToastText(message);
    setToastSeverity('info');
    setShowToast(true);
  };

  const handleLogoUpload = () => {
    dispatch(
      openUploadDialog({
        brandUid: brand.uid,
        isLogo: true,
        refetch,
        setErrorMessage: handleUploadError,
      })
    );
  };

  const handleToggleFeaturedLogo = (item, isFeatured) => {
    updateUpload(item.id, { is_default: isFeatured, brand_uid: brandUid });
  };

  if (isBrandForm && isLoading) {
    return <LoaderContainer />;
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Toast open={showToast} severity={toastSeverity} onClose={closeToast}>
        {toastText}
      </Toast>
      {/* Brand Name (brands only) */}
      {isBrandForm && (
        <Grid container item xs={12}>
          {!!brand.is_default && (
            <span className={classes.defaultBrandText}>
              This is your organization’s default brand.
            </span>
          )}
          {editMode && (
            <InputLabel
              className={classes.inputLabel}
              style={{ marginBottom: theme.spacing(1) }}
              htmlFor="styles-typography"
            >
              Brand Name
            </InputLabel>
          )}
          <Grid container className={classes.brandNameActionContainer}>
            {editMode ? (
              <Grid container item xs={12}>
                <OutlinedInput
                  classes={{
                    disabled: classes.disabledInput,
                  }}
                  color="secondary"
                  fullWidth
                  value={name}
                  disabled={disabled}
                  onChange={handleNameChange}
                />
              </Grid>
            ) : (
              <Grid item xs>
                <Typography variant="h4" className={classes.brandNameView}>
                  {name}
                </Typography>
              </Grid>
            )}
            {!editMode && (
              <StylesFormButtons
                appliedBrandUid={appliedBrandUid}
                editMode={editMode}
                isBrandForm
                isDefault={brand.is_default}
                isDrawer={isDrawer}
                isNewBrand={false}
                onApplyBrand={() => onApplyBrand(brand)}
                onApplyStyle={handleApplyStyle}
                onDeleteBrand={() => onDeleteBrand(brandUid)}
                onEditBrand={() => navigateToBrand(brandUid, false, true)}
                onSetStyleAsDefault={handleSetStyleAsDefault}
                onSaveBrand={handleSaveBrand}
              />
            )}
          </Grid>
        </Grid>
      )}
      {/* Colors/Brand Colors */}
      {/* TODO make the contents of this grid container into a component */}
      <Grid
        container
        item
        style={isEnterprise ? {} : { marginTop: theme.spacing(4) }}
        spacing={1}
      >
        <Grid item xs={12}>
          <div className={classes.inputLabelGroup}>
            <InputLabel className={classes.inputLabel}>
              {`${isBrandForm ? 'Brand ' : ''}Colors`}
            </InputLabel>
            {editMode && (
              <Tooltip title="Quickly access brand colors in the editor">
                <SvgIcon
                  className={classes.infoTooltip}
                  component={QuestionCircleIcon}
                />
              </Tooltip>
            )}
          </div>
        </Grid>
        <Grid container item spacing={1} xs={12}>
          {editMode || !isEnterprise ? (
            // Edit mode
            <>
              {colors.map((c, idx) => (
                <div
                  key={`brand-color-edit-${idx}`}
                  data-testid={`brand-color-edit-${idx}`}
                  className={classes.colorPickerListItem}
                >
                  <div className={classes.colorPickerListItemValue}>
                    <ColorPickerPopover
                      disabled={disabled}
                      color={c}
                      onChange={(newColor) => handleColorChange(idx, newColor)}
                      showNoneOption={false}
                    />
                  </div>
                  <div className={classes.colorPickerListItemDelete}>
                    <IconButton
                      disabled={idx < 2}
                      onClick={() => handleColorDelete(idx, c)}
                    >
                      <Bin
                        className={clsx(classes.colorDeleteIcon, {
                          [classes.colorDeleteIconDisabled]: idx < 2,
                        })}
                        data-testid={`brand-color-delete-${idx}`}
                      />
                    </IconButton>
                  </div>
                </div>
              ))}
              {colors.length < MAX_BRAND_COLORS && (
                <div className={classes.colorPickerListItem}>
                  <div className={classes.colorPickerListItemValue}>
                    <AddColorButton onClick={handleColorAdd} />
                  </div>
                  <div
                    className={classes.colorPickerListItemDelete}
                    aria-hidden
                  >
                    {/* Leave space for alignment */}
                  </div>
                </div>
              )}
            </>
          ) : (
            // View mode for brands
            <Grid container item spacing={2} justifyContent="flex-start">
              {colors.map((c, idx) => (
                <Grid
                  key={`brand-color-view-${idx}`}
                  container
                  item
                  alignItems="center"
                  wrap="nowrap"
                  xs={isDrawer ? 4 : 3}
                >
                  <Button
                    disabled={true}
                    className={classes.viewOnlySwatch}
                    style={{ backgroundColor: tinycolor(c).toRgbString() }}
                    aria-label={toUpperHexString(c)}
                  />
                  <Typography variant="button" className={classes.swatchLabel}>
                    {toUpperHexString(tinycolor(c).toRgbString())}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      {/* Text/Accent Color Select */}
      {/* TODO make the contents of this grid container into a component */}
      {(editMode || !isEnterprise) && (
        <Grid container item spacing={1} xs={12}>
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>Text Color</InputLabel>
            </Grid>
            <Grid container item xs={12}>
              <ColorGrid
                colors={colors.map((c) => tinycolor(c).toRgbString())}
                itemsPerRow={5}
                onChange={handleTextColorChange}
                roundedSwatches
                selectedColor={primaryTextColor}
              />
            </Grid>
          </Grid>
          <Grid container item xs={6}>
            <Grid item xs={12} className={classes.inputLabel}>
              <div className={classes.inputLabelGroup}>
                <InputLabel className={classes.inputLabel}>
                  Accent Color
                </InputLabel>
                <Tooltip title="Set a secondary color for highlights & animations">
                  <SvgIcon
                    className={classes.infoTooltip}
                    component={QuestionCircleIcon}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6}>
              <ColorGrid
                colors={colors.map((c) => tinycolor(c).toRgbString())}
                itemsPerRow={5}
                onChange={handleAccentColorChange}
                roundedSwatches
                selectedColor={secondaryTextColor}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Font Styles */}
      {/* TODO make the contents of this grid container into a component */}
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <div className={classes.inputLabelGroup}>
            <InputLabel
              className={classes.inputLabel}
              htmlFor="image-color-overlay-style"
            >
              Font Styles
            </InputLabel>
            <Tooltip title="Customize text to match your brand">
              <SvgIcon
                className={classes.infoTooltip}
                component={QuestionCircleIcon}
              />
            </Tooltip>
          </div>
          <Box padding={theme.spacing(1.5, 0)}>
            <FontPreview
              textVariant={TEXT_VARIANT.HEADER}
              editMode={editMode || !isEnterprise}
              isDrawer={isDrawer}
              onStylesUpdate={(f) =>
                handleTypographyChange(TEXT_VARIANT.HEADER, f)
              }
              previewText="Header"
              styles={{
                primaryTextColor: styles?.primaryTextColor,
                font: Object.assign(
                  {},
                  DEFAULT_HEADER_FONT,
                  styles?.fonts?.header
                ),
              }}
            />
            <FontPreview
              textVariant={TEXT_VARIANT.BODY}
              editMode={editMode || !isEnterprise}
              isDrawer={isDrawer}
              onStylesUpdate={(f) =>
                handleTypographyChange(TEXT_VARIANT.BODY, f)
              }
              previewText="Body"
              styles={{
                primaryTextColor: styles?.primaryTextColor,
                font: Object.assign({}, DEFAULT_BODY_FONT, styles?.fonts?.body),
              }}
            />
          </Box>
        </Grid>
      </Grid>
      {/* Video/Image Overlay colors */}
      <Grid container item spacing={2}>
        <Grid item xs={6}>
          <StylesFormColorEditor
            disabled={disabled}
            editMode={editMode || !isEnterprise}
            color={videoTint}
            onColorChange={handleVideoColorOverlayChange}
            label="Video Overlay"
            tooltip="Set a default color tint on your videos"
            classes={classes}
          />
        </Grid>
        <Grid item xs={6}>
          <StylesFormColorEditor
            disabled={disabled}
            editMode={editMode || !isEnterprise}
            color={imageTint}
            onColorChange={handleImageColorOverlayChange}
            label="Image Overlay"
            tooltip="Set a default color tint on your images"
            classes={classes}
          />
        </Grid>
      </Grid>
      {/* Logo Section (brands only) */}
      {/* TODO make the contents of this grid container into a component */}
      {isBrandForm && (
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            {isOrgAdmin && (
              <InputLabel
                className={clsx(classes.inputLabel, classes.logosCount)}
                htmlFor="upload-logo"
              >
                {`Logos and Images${
                  !!logos.length ? ` (${logos.length})` : ``
                }`}
              </InputLabel>
            )}
            {!!logos.length || editMode ? (
              <ResultsGrid columns={isDrawer ? 2 : 3}>
                {logos.map((logo) => (
                  <UploadPreview
                    showAdd={isDrawer}
                    showDelete={isOrgAdmin && editMode}
                    showFeatured={isOrgAdmin && editMode}
                    isFeatured={logo.pivot?.is_default}
                    mediaType={MediaTypes.IMAGE}
                    onSelect={handleLogoSelect}
                    onDelete={handleLogoDelete}
                    onToggleFeatured={handleToggleFeaturedLogo}
                    key={logo.id}
                    upload={logo}
                  />
                ))}
                {isOrgAdmin && editMode && (
                  <UploadButton id="upload-logo" onClick={handleLogoUpload} />
                )}
              </ResultsGrid>
            ) : (
              <Grid container item spacing={2}>
                <Grid item xs={12} className={classes.emptyLogosContainer}>
                  <span className={classes.emptyLogosText}>
                    <p>This brand does not contain any logos yet.</p>
                    {!isOrgAdmin && (
                      <p>Only your admin can create or make changes.</p>
                    )}
                  </span>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      {!isEnterprise && (
        <StylesFormButtons
          onSetStyleAsDefault={handleSetStyleAsDefault}
          onApplyStyle={handleApplyStyle}
          isBrandForm={isBrandForm}
          isDrawer={isDrawer}
        />
      )}

      {editMode && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={isNewBrand ? handleCreateNewBrand : handleSaveBrand}
          >
            {isNewBrand && isDrawer ? 'Save as New Brand' : 'Save'}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
