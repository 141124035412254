import { DND_ZONES } from '../constants/Constants';

// Miscellaneous
export const UPLOAD = 'upload'; // user uploads content
export const UPLOAD_DELETE = 'upload.delete'; // delete a piece of user-uploaded content
export const UPLOAD_ASSOCIATE = 'upload.associate'; // associate a piece of user-uploaded content with a project, template, or brand
export const UPLOAD_DISASSOCIATE = 'upload.disassociate'; // disassociate a piece of user-uploaded content with a project, template, or brand

// Misc - Redux
export const USER_LOGOUT = 'user.logout';

// Styles
export const APPLY_STYLES = 'styles.apply';
export const SET_DEFAULT_STYLES = 'styles.set-default';
export const CHANGE_STYLES_TYPEFACE = 'styles.change.typeface';
export const CHANGE_STYLES_PRIMARY_TEXT_COLOR =
  'styles.change.primary-text-color';
export const CHANGE_STYLES_SECONDARY_TEXT_COLOR =
  'styles.change.secondary-text-color';
export const CHANGE_STYLES_VIDEO_COLOR_OVERLAY =
  'styles.change.video-color-overlay';
export const CHANGE_STYLES_IMAGE_COLOR_OVERLAY =
  'styles.change.image-color-overlay';

// Upsell Dialogs
export const UPSELL_GO_TO_ACCOUNT = 'upsell.account-page'; // click go to account link in confirmation dialog
export const UPSELL_MODAL_OPEN = 'upsell.modal.open'; // open upsell dialog on editor
export const UPSELL_SIGN_UP = 'upsell.sign-up'; // click sign-up link in upsell dialog
export const UPSELL_SIGN_UP_ENT = 'upsell.sign-up-ent'; // click sign-up link in upsell dialog as enterprise user

// Publish Sidebar Starter Upgrade Links
export const UPSELL_EXPORT_ALERT = 'upsell.export.alert';

// Share Page
export const SHARE_COPY_SHARE_LINK = 'share.copy.share-link'; // click copy share link on share page
export const SHARE_CREATE_VIDEO = 'share.create.video'; // click create a video on share page

// Team space
export const MY_PROJECT_TAB = 'projects.tab'; // click the My Projects tab on the projects page
export const TEAM_PROJECT_TAB = 'team.projects.tab'; // click the Team Projects tab on the projects page
export const TEAM_PROJECT_SHARE = 'team.project.share'; // toggle team sharing ON on the projects page
export const TEAM_PROJECT_UNSHARE = 'team.project.unshare'; // toggle team sharing OFF on the projects page

/**
 * New approach to events
 * Tag Structure: [context?].[object].[action]
 *
 * Top-level objects below are contexts and objects
 * (i.e. they can be either depending on the scenario)
 */

export const CANVAS = {
  ITEM_ADD: 'canvas.item.add',
  ITEM_DROP: 'canvas.item.drop',
  ITEM_EDIT: 'canvas.item.edit',
};

export const EDITOR = {
  MUTE: 'editor.mute',
  UNMUTE: 'editor.unmute',
  PLAY: 'editor.play',
  PAUSE: 'editor.pause',
  AUTOSAVE: 'editor.autosave',
};

export const EXPORT = {
  SETTINGS: 'export.settings',
  DOWNLOAD: 'export.download',
  COPY_SHARE_LINK: 'export.copy.share-link',
};

export const KEYBOARD = {
  MUTE: 'keyboard.mute',
  PAUSE: 'keyboard.pause',
  PLAY: 'keyboard.play',
  REDO: 'keyboard.redo',
  SHORTCUTS_MENU: 'keyboard.shortcuts-menu',
  UNDO: 'keyboard.undo',
  ZOOM_IN: 'keyboard.zoom-in',
  ZOOM_OUT: 'keyboard.zoom-out',

  // Item-level actions
  ITEM_DELETE: 'keyboard.item.delete',
  ITEM_DUPLICATE: 'keyboard.item.duplicate',
  ITEM_SPLIT: 'keyboard.item.split',
  ITEM_TRIM: 'keyboard.item.trim',
};

export const NAVBAR = {
  FAQ_CLICK: 'navbar.faq.click',
};

export const PROJECT = {
  CREATE: 'project.create',
  DELETE: 'project.delete',
  DUPLICATE: 'project.duplicate',
  EDIT: 'project.edit',
  EXPORT: 'project.export',
  LOAD: 'project.load',
};

export const PROPERTIES_PANEL = {
  CLOSE: 'properties-panel.close',

  // Item-level actions
  ITEM_EDIT: 'properties-panel.item.edit',
  BRAND_APPLIED: 'properties-panel.item.brand-applied',
  ITEM_TRIM_MODAL_OPEN: 'properties-panel.trim-modal.open',
  UPLOAD_DELETE: 'properties-panel.upload.delete',
};

export const SIDE_DRAWER = {
  // Side drawer UI
  OPEN: 'side-drawer.open',
  CLOSE: 'side-drawer.close',

  // Side drawer search, filter, and results
  FILTER_OPEN: 'side-drawer.filter.open',
  FILTER_REMOVE: 'side-drawer.filter.remove',
  FILTER_SELECT: 'side-drawer.filter.select',
  FILTERS_HIDE: 'side-drawer.filters.hide',
  FILTERS_SHOW: 'side-drawer.filters.show',
  FOLDER_OPEN: 'side-drawer.folder.open',
  MEDIA_TYPE_SELECT: 'side-drawer.media-type.select',
  SEARCH_LOAD_MORE: 'side-drawer.search.load-more',
  SEARCH_FILTERS: 'side-drawer.search.filters',
  SEARCH_TEXT: 'side-drawer.search.text',

  // Item-level actions
  ITEM_ADD: 'side-drawer.item.add',
  ITEM_DROP: 'side-drawer.item.drop',
  ITEM_PREVIEW: 'side-drawer.item.preview',
  ITEM_START_DRAG: 'side-drawer.item.start-drag',
  TRIM_MODAL_OPEN: 'side-drawer.trim-modal.open',
  UPLOAD_DELETE: 'side-drawer.upload.delete',

  // Styles
  STYLES_APPLY: 'side-drawer.styles.apply',
  STYLES_EDIT: 'side-drawer.styles.edit',
  STYLES_SET_DEFAULT: 'side-drawer.styles.set-default',
};

export const BRANDS = {
  START_CREATE: 'brands.create',
  CONFIRM_CREATE: 'brands.add',
  DETAIL: 'brands.detail',
  SAVE: 'brands.save',
  DELETE: 'brands.delete',
  APPLY_START: 'brands.apply',
  SET_DEFAULT_BRAND: 'brands.default.set',
  UNSET_DEFAULT_BRAND: 'brands.default.unset',
  APPLY_CONFIRM: 'modal.brands.apply',
  ACCEPT_UPDATES: 'modal.brands.update',
  REJECT_UPDATES: 'modal.brands.reject',
};

export const TEMPLATES = {
  FILTER_SELECT: 'templates.filter.select',
  LOAD_MORE: 'templates.load-more',
};

export const TEMPLATE = {
  PREVIEW: 'template.preview',
  COPY: 'template.copy',
  CREATE: 'template.create',
  START_EDIT: 'template.start-edit',
  DELETE: 'template.delete',
};

export const TIMELINE = {
  ITEM_START_DRAG: 'timeline.item.start-drag',
  ITEM_ADD: 'timeline.item.add',
  ITEM_DROP: 'timeline.item.drop',
  ITEM_EDIT: 'timeline.item.edit',
  ITEM_MOVE: 'timeline.item.move',
  ITEM_REPLACE: 'timeline.item.replace',
  ITEM_SELECT: 'timeline.item.select',
};

export const TOOLBAR = {
  UNDO: 'toolbar.undo',
  REDO: 'toolbar.redo',
  ZOOM_OUT: 'toolbar.zoom-out',
  ZOOM_IN: 'toolbar.zoom-in',

  // Item-level actions
  ITEM_DUPLICATE: 'toolbar.item.duplicate',
  ITEM_DELETE: 'toolbar.item.delete',
  ITEM_SPLIT: 'toolbar.item.split',
  ITEM_TRIM_MODAL_OPEN: 'toolbar.trim-modal.open',
};

export const TUTORIALS = {
  QUICK_LINKS: 'tutorials.quick-link',
};

export const TRIM_DIALOG = {
  CLOSE: 'trim-modal.close',

  // Item-level actions
  ITEM_ADD: 'trim-modal.item.add',
  ITEM_EDIT: 'trim-modal.item.edit',
};

export function getDragContext(zone) {
  switch (zone) {
    case DND_ZONES.CANVAS:
      return CANVAS;
    case DND_ZONES.DRAWER:
      return SIDE_DRAWER;
    case DND_ZONES.TIMELINE:
      return TIMELINE;
    default:
      return null;
  }
}
