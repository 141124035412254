import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';

import { DND_ZONES, SOURCE_TYPES } from '../constants/Constants';
import { checkMediaType } from '../utils/mediaUtils';
import AddIconButton from './AddIconButton';
import DeleteIconButton from './DeleteIconButton';
import Draggable from './Draggable';
import FeaturedButton from './FeaturedButton';
import MediaPreview from './MediaPreview';
import PositionWrapper, { Positions } from './PositionWrapper';
import DragPreview from './assetDrawer/DragPreview';

const useStyles = makeStyles({
  itemWrapper: {
    '&:not(:hover) .add-button, &:not(:hover) .delete-button, &:not(:hover) .featured-button-on-hover':
      {
        display: 'none',
      },
  },
});

export default function UploadPreview(props) {
  const {
    isFeatured = false,
    mediaType,
    onDelete,
    onSelect,
    onToggleFeatured,
    showAdd = true,
    showDelete = true,
    showFeatured = false,
    upload = {},
  } = props;
  const { files, name, meta = {} } = upload;
  const { url } = files?.[0] || {};
  const classes = useStyles();

  const { isAudio } = checkMediaType(mediaType);

  const aspectRatio = meta.aspect_ratio;
  const duration = meta.duration;
  const includesAudio = !!meta.audio_codec;

  const volume = isAudio ? 1 : includesAudio ? 1 : 0;

  const item = {
    ...upload,
    aspectRatio,
    duration,
    includesAudio,
    mediaType,
    sourceType: SOURCE_TYPES.UPLOAD,
    volume,
  };

  const handleClick = (event) => onSelect(event, item);

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete(event, item);
  };

  return (
    <Box position="relative" className={classes.itemWrapper}>
      <Draggable
        data={{
          item,
          overlayElement: () => (
            <DragPreview mediaType={mediaType} previewUrl={url} />
          ),
          zone: DND_ZONES.DRAWER,
        }}
        id={upload.id}
      >
        <MediaPreview
          mediaType={mediaType}
          objectFit="contain"
          previewUrl={url}
          onClick={handleClick}
          title={name}
        />
      </Draggable>
      {showFeatured && (
        <PositionWrapper position={Positions.TOP_LEFT}>
          <FeaturedButton
            aria-label={`${isFeatured ? 'Unset' : 'Set'} featured logo`}
            onClick={() => onToggleFeatured(item, !isFeatured)}
            isFeatured={isFeatured}
          />
        </PositionWrapper>
      )}
      {showDelete && (
        <PositionWrapper position={Positions.TOP_RIGHT}>
          <DeleteIconButton
            aria-label={`Delete ${mediaType}`}
            onClick={handleDelete}
          />
        </PositionWrapper>
      )}
      {showAdd && (
        <PositionWrapper position={Positions.BOTTOM_RIGHT}>
          <AddIconButton
            aria-label={`Add ${mediaType}`}
            onClick={handleClick}
          />
        </PositionWrapper>
      )}
    </Box>
  );
}

UploadPreview.propTypes = {
  mediaType: PropTypes.oneOf([
    MediaTypes.AUDIO,
    MediaTypes.IMAGE,
    MediaTypes.VIDEO,
  ]),
  upload: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
};
