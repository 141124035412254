import { makeStyles } from '@material-ui/core/styles';
import { NativeMediaControls } from '@videoblocks/jelly-renderer';
import PropTypes from 'prop-types';
import { memo, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useDebouncedCallback } from 'use-debounce';

const useStyles = makeStyles({
  thumb: {
    display: 'inline',
  },
});

/**
 * FilmStrip creates a static 2d representation of a video
 * by drawing video thumbnail images side by side
 */
function FilmStrip(props) {
  const { width = 0, height = 0, source, onError } = props;
  const [thumbs, setThumbs] = useState([]);
  const classes = useStyles();

  /**
   * updateThumbs is an expensive operation. it gets called each
   * time user clicks "zoom-in/out". Debounce delays function for
   * repetitive clicks to avoid repeated executions which hog resources
   */
  const updateVideoThumbs = useDebouncedCallback(
    async (video, width, height, isMounted) => {
      const thumbs = await getVideoThumbs(video, width, height, isMounted);
      setThumbs(thumbs);
    },
    // 400 ms = roughly 3 clicks per second
    400
  );

  useAsyncEffect(
    async (isMounted) => {
      try {
        const video = await NativeMediaControls.preloadOffscreenVideo(source);
        await updateVideoThumbs(video, width, height, isMounted);
      } catch (error) {
        if (onError) onError(error);
      }
    },
    [source, width, height, onError]
  );

  const containerStyle = {
    width,
    height,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={containerStyle}>
      {thumbs.map((thumb, i) => (
        <img className={classes.thumb} src={thumb} key={i} alt="" />
      ))}
    </div>
  );
}

async function getVideoThumbs(video, stripWidth, stripHeight, isMounted) {
  const controls = new NativeMediaControls(video);
  const secondsPerPixel = video.duration / stripWidth;

  const [thumbWidth, thumbHeight] = getThumbDimensions(video, stripHeight);
  const canvas = createCanvas(thumbWidth, thumbHeight);
  const context = canvas.getContext('2d');

  const thumbs = [];

  for (let position = 0; position < stripWidth; position += thumbWidth) {
    // this is an expensive operation. If component is no longer mounted, stop exection.
    if (!isMounted()) return [];
    const timestamp = position * secondsPerPixel;
    await controls.seek(timestamp);
    context.drawImage(video, 0, 0, thumbWidth, thumbHeight);
    thumbs.push(canvas.toDataURL());
  }
  return thumbs;
}

function getThumbDimensions(video, stripHeight) {
  const { videoWidth, videoHeight } = video;
  const aspectRatio = videoWidth / videoHeight;
  const thumbWidth = stripHeight * aspectRatio;
  const thumbHeight = stripHeight;
  return [thumbWidth, thumbHeight];
}

function createCanvas(width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

FilmStrip.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  source: PropTypes.string,
  onError: PropTypes.func,
};

export default memo(FilmStrip);
