export const FALLBACK_FONT = {
  name: 'Assistant Bold',
  family: 'Assistant',
  weight: 700,
};
export const FALLBACK_FONT_NAME = FALLBACK_FONT.name;

export default [
  {
    name: 'Abril Fatface',
    family: 'Abril Fatface',
    weight: 400,
  },
  {
    name: 'Alfa Slab One',
    family: 'Alfa Slab One',
    weight: 400,
  },
  {
    name: 'Amatic SC',
    family: 'Amatic SC',
    weight: 400,
  },
  {
    name: 'Amatic SC Bold',
    family: 'Amatic SC',
    weight: 700,
  },
  {
    name: 'Anton',
    family: 'Anton',
    weight: 400,
  },
  {
    name: 'Assistant',
    family: 'Assistant',
    weight: 400,
  },
  {
    name: 'Assistant Bold',
    family: 'Assistant',
    weight: 700,
  },
  {
    name: 'Bangers',
    family: 'Bangers',
    weight: 400,
  },
  {
    name: 'Cabin Sketch',
    family: 'Cabin Sketch',
    weight: 400,
  },
  {
    name: 'Caveat Brush',
    family: 'Caveat Brush',
    weight: 400,
  },
  {
    name: 'Creepster',
    family: 'Creepster',
    weight: 400,
  },
  {
    name: 'Crete Round',
    family: 'Crete Round',
    weight: 400,
  },
  {
    name: 'Fjalla One',
    family: 'Fjalla One',
    weight: 400,
  },
  {
    name: 'Fredericka the Great',
    family: 'Fredericka the Great',
    weight: 400,
  },
  {
    name: 'Gentium Basic',
    family: 'Gentium Basic',
    weight: 400,
  },
  {
    name: 'Lato',
    family: 'Lato',
    weight: 400,
  },
  {
    name: 'Lobster',
    family: 'Lobster',
    weight: 400,
  },
  {
    name: 'Love Ya Like A Sister',
    family: 'Love Ya Like A Sister',
    weight: 400,
  },
  {
    name: 'Monoton',
    family: 'Monoton',
    weight: 400,
  },
  {
    name: 'Merriweather',
    family: 'Merriweather',
    weight: 400,
  },
  {
    name: 'Montserrat',
    family: 'Montserrat',
    weight: 400,
  },
  {
    name: 'Montserrat Bold',
    family: 'Montserrat',
    weight: 700,
  },
  {
    name: 'Montserrat ExtraBold',
    family: 'Montserrat',
    weight: 800,
  },
  {
    name: 'Noto Sans',
    family: 'Noto Sans',
    weight: 400,
  },
  {
    name: 'Noto Serif',
    family: 'Noto Serif',
    weight: 400,
  },
  {
    name: 'Open Sans',
    family: 'Open Sans',
    weight: 400,
  },
  {
    name: 'Open Sans Condensed',
    family: 'Open Sans Condensed',
    weight: 300,
  },
  {
    name: 'Oswald',
    family: 'Oswald',
    weight: 400,
  },
  {
    name: 'Patua One',
    family: 'Patua One',
    weight: 400,
  },
  {
    name: 'Playfair Display',
    family: 'Playfair Display',
    weight: 400,
  },
  {
    name: 'Poor Story',
    family: 'Poor Story',
    weight: 400,
  },
  {
    name: 'Poppins',
    family: 'Poppins',
    weight: 400,
  },
  {
    name: 'Poppins Bold',
    family: 'Poppins',
    weight: 700,
  },
  {
    name: 'PT Sans Narrow',
    family: 'PT Sans Narrow',
    weight: 400,
  },
  {
    name: 'Quicksand',
    family: 'Quicksand',
    weight: 400,
  },
  {
    name: 'Raleway',
    family: 'Raleway',
    weight: 400,
  },
  {
    name: 'Raleway Bold',
    family: 'Raleway',
    weight: 700,
  },
  {
    name: 'Raleway Black',
    family: 'Raleway',
    weight: 900,
  },
  {
    name: 'Roboto',
    family: 'Roboto',
    weight: 400,
  },
  {
    name: 'Roboto Condensed',
    family: 'Roboto Condensed',
    weight: 400,
  },
  {
    name: 'Rubik',
    family: 'Rubik',
    weight: 400,
  },
  {
    name: 'Rubik Bold',
    family: 'Rubik',
    weight: 700,
  },
  {
    name: 'Rubik Black',
    family: 'Rubik',
    weight: 900,
  },
  {
    name: 'Shrikhand',
    family: 'Shrikhand',
    weight: 400,
  },
  {
    name: 'Source Sans Pro',
    family: 'Source Sans Pro',
    weight: 400,
  },
  {
    name: 'Suez One',
    family: 'Suez One',
    weight: 400,
  },
  {
    name: 'Titillium Web',
    family: 'Titillium Web',
    weight: 400,
  },
  {
    name: 'Titillium Web Black',
    family: 'Titillium Web',
    weight: 900,
  },
  {
    name: 'Varela Round',
    family: 'Varela Round',
    weight: 400,
  },
  {
    name: 'Yellowtail',
    family: 'Yellowtail',
    weight: 400,
  },
];
