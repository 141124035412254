import { MediaTypes } from '@videoblocks/jelly-renderer';

import { SOURCE_TYPES } from '../constants/Constants';
import getMediaDuration from '../utils/getMediaDuration';
import createSourcedObject from './createSourcedObject';

/**
 * Creates a video object from a source item object
 *
 * @param {Object} item - the source item
 * @returns {Video} Returns a video object
 */
export default async function createVideoObject(item = {}) {
  const source =
    item.preview_urls?._720p ||
    item.preview_urls?._480p ||
    item.preview_urls?._360p ||
    item.preview_urls?._180p ||
    item.preview_url ||
    item.files?.[0]?.url;
  let duration = item.durationMs ? item.durationMs / 1000 : item.duration;

  if (!duration) {
    duration = await getMediaDuration(source);
  }

  return createSourcedObject({
    // base attributes
    duration,
    mediaType: MediaTypes.VIDEO,

    // sourced attributes
    itemSourceId: item.id,
    itemSourceType: item.sourceType || SOURCE_TYPES.STOCK, // stock || upload
    maxDuration: duration,
    source,
    thumbnail: item.thumbnail_url,
    resolutions: item.preview_urls,
    title: item.title || item.name,

    // video attributes
    aspectRatio: 16 / 9,
    filters: {},
    position: { x: 'center', y: 'center' },

    opacity: 1,
    fadeIn: 0,
    fadeOut: 0,
    includesAudio: !!item.meta?.audio_codec,
    volume: getVolume(item),
  });
}

function getVolume(item) {
  // we assume stock videos are muted by default
  if (item.sourceType === SOURCE_TYPES.STOCK) {
    return 0;
  }
  return item.volume ?? 1;
}
