import { Dialog } from '@material-ui/core';
import robodog from '@uppy/robodog';
import { flatMap } from 'lodash';
import log from 'loglevel';
import { useEffect, useRef } from 'react';

import TransloaditAPI from '../../api/TransloaditAPI';
import UploadAPI from '../../api/UploadAPI';
import {
  FILE_UPLOAD_NUMBER_MAX,
  FILE_UPLOAD_SIZE_MAX,
  LOGO_UPLOAD_FILE_TYPES,
  MEDIA_UPLOAD_FILE_TYPES,
  MEDIA_UPLOAD_PROVIDERS,
} from '../../constants/Constants';
import { trackUploads } from '../../events/sendEvents';

export default function UploadDialog({
  brandUid,
  templateUid,
  isLogo,
  onClose = () => {},
  open = false,
  refetch = () => {},
  setErrorMessage = () => {},
}) {
  const dashboardRef = useRef(null);

  useEffect(() => {
    const uploadResult = (result = {}, assemblyId) => {
      return new UploadAPI()
        .postUpload({
          name: result.basename,
          mimetype: result.mime,
          size: result.size,
          filename: `${result.id}.${result.ext}`,
          encoder_id: assemblyId,
          is_logo: isLogo,
          meta: result.meta,
          brand_uid: brandUid,
          template_uid: templateUid,
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            setErrorMessage('Each brand has a limit of 10 logos.');
          }
        });
    };

    (async () => {
      const response = await new TransloaditAPI().getSignatureAuth();
      const { expires, key, signature, templateId, userId } = response;
      const dashboardContents = dashboardRef?.current?.children;
      if (!!dashboardContents && dashboardContents.length === 0) {
        robodog
          .dashboard('#robodog-dashboard', {
            params: {
              auth: { expires, key },
              template_id: templateId,
              fields: { userId },
            },
            providers: MEDIA_UPLOAD_PROVIDERS,
            restrictions: {
              allowedFileTypes: isLogo
                ? LOGO_UPLOAD_FILE_TYPES
                : MEDIA_UPLOAD_FILE_TYPES,
              maxFileSize: FILE_UPLOAD_SIZE_MAX,
              maxNumberOfFiles: FILE_UPLOAD_NUMBER_MAX,
            },
            signature,
            waitForEncoding: true,
            proudlyDisplayPoweredByUppy: false,
          })
          .on('transloadit:complete', async (assembly) => {
            const { assembly_id: assemblyId } = assembly;
            trackUploads(assembly.uploads);
            const results = flatMap(assembly.results);
            await Promise.all(
              results.map((result) => uploadResult(result, assemblyId))
            );
            onClose();
            refetch();
          })
          .on('error', (error) => {
            log.error('Uppy robodog error', { error });
          });
      }
    })();
  }, [brandUid, templateUid, isLogo, onClose, refetch, setErrorMessage]);

  return (
    <Dialog open={open} onClose={onClose}>
      <div id="robodog-dashboard" ref={dashboardRef} />
    </Dialog>
  );
}
