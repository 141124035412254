// Projects
export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';
export const LOAD_PROJECT_REQUESTED = 'LOAD_PROJECT_REQUESTED';
export const LOAD_PROJECT_COMPLETED = 'LOAD_PROJECT_COMPLETED';
export const LOAD_PROJECT_FAILED = 'LOAD_PROJECT_FAILED';
export const STORE_PROJECT_REQUESTED = 'STORE_PROJECT_REQUESTED';
export const STORE_PROJECT_COMPLETED = 'STORE_PROJECT_COMPLETED';
export const STORE_PROJECT_FAILED = 'STORE_PROJECT_FAILED';
export const SET_WORKSPACE_PROJECT_ID = 'SET_WORKSPACE_PROJECT_ID';

// User
export const USER_LOAD_USER_REQUESTED = 'AUTH_LOAD_USER_REQUESTED';
export const USER_LOAD_USER_COMPLETED = 'AUTH_LOAD_USER_COMPLETED';
export const USER_LOAD_USER_FAILED = 'AUTH_LOAD_USER_FAILED';
export const USER_LOGOUT = 'AUTH_LOGOUT';
