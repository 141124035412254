import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  SEARCH_RESULTS_PAGE_SIZE,
  SOURCE_TYPES,
} from '../../constants/Constants';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import { selectMediaType } from '../../features/assetDrawer/assetDrawerSlice';
import {
  openConfirmationDialog,
  openUploadDialog,
} from '../../features/ui/uiSlice';
import useUploads from '../../hooks/useUploads';
import { selectIsEnterprise } from '../../selectors/user';
import formatPropsToCamelCase from '../../utils/formatPropsToCamelCase';
import { checkMediaType } from '../../utils/mediaUtils';
import LoadMoreButton from '../LoadMoreButton';
import UploadPreview from '../UploadPreview';
import AssetDrawerHeader from './AssetDrawerHeader';
import ResultsGrid from './ResultsGrid';
import UploadButton from './UploadButton';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    overflowY: 'scroll',
  },
}));

export default function UploadsDrawer({ onSelect }) {
  const dispatch = useDispatch();
  const { projectId, templateId } = useParams();
  const mediaType = useSelector(selectMediaType);
  const classes = useStyles();
  const {
    data,
    deleteUpload,
    associateUpload,
    isLoadingMore,
    isReachingEnd,
    loadMore,
    refetch,
  } = useUploads(mediaType, { pageSize: SEARCH_RESULTS_PAGE_SIZE });

  const { isImage } = checkMediaType(mediaType);

  const isEnterprise = useSelector(selectIsEnterprise);

  const handleUploadButtonClick = () => {
    dispatch(openUploadDialog({ refetch: refetch }));
  };

  const handleLoadMore = () => {
    loadMore();
  };

  const handleUploadDelete = async (event, upload) => {
    dispatch(
      openConfirmationDialog({
        title: 'Delete Upload?',
        text: (
          <>
            It will no longer be available to add to projects
            {isEnterprise ? ' and templates' : ''}.
          </>
        ),
        confirmButtonText: 'Delete',
        danger: true,
        showCancel: true,
        onConfirm: async () => {
          await deleteUpload(upload.id);
          trackEvent(SIDE_DRAWER.UPLOAD_DELETE, formatPropsToCamelCase(upload));
        },
      })
    );
  };

  const handleUploadSelect = async (event, upload) => {
    onSelect(event, {
      ...upload,
      mediaType: upload.media_type,
      sourceType: SOURCE_TYPES.UPLOAD,
    });
    // associate upload here if image; otherwise associate after adding clip from trim modal
    if (isImage) {
      await associateUpload({
        uploadId: upload.id,
        projectUid: projectId,
        templateUid: templateId,
      });
    }
  };

  return (
    <div className={classes.drawer}>
      <AssetDrawerHeader />
      <div className={classes.content}>
        <ResultsGrid>
          <UploadButton onClick={handleUploadButtonClick} />
          {data.map((upload) => (
            <UploadPreview
              key={upload.id}
              mediaType={mediaType}
              onSelect={handleUploadSelect}
              onDelete={handleUploadDelete}
              upload={upload}
            />
          ))}
        </ResultsGrid>
        <LoadMoreButton
          isLoadingMore={isLoadingMore}
          isReachingEnd={isReachingEnd}
          onClick={handleLoadMore}
        />
      </div>
    </div>
  );
}
