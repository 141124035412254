import { createSelector } from '@reduxjs/toolkit';

import {
  UNLIMITED_DOWNLOADS,
  UNLIMITED_EXPORTS,
} from '../constants/Permissions';
import { PLAN_CLASSIFICATIONS } from '../constants/Plan';
import formatFullName from '../utils/formatFullName';

/* Base Selectors */

export const selectEmail = (state) => state.user.email;

export const selectFirstName = (state) => state.user.firstName;

export const selectIsStoryblocksAdmin = (state) =>
  state.user.permissions.isStoryblocksAdmin;

export const selectIsOrgAdmin = (state) => state.user.permissions.isOrgAdmin;

export const selectIsEnterprise = (state) => state.user.isEnterprise;

export const selectHasVisitedMaker = (state) => state.user.hasVisitedMaker;

export const selectIsLoading = (state) => state.user.isLoading;

export const selectLastName = (state) => state.user.lastName;

export const selectMaxExportsPerMonth = (state) =>
  state.user.permissions.maxExportsPerMonth;

export const selectMonthlyExports = (state) =>
  state.user.permissions.monthlyExports;

export const selectOrganizationId = (state) => state.user.organizationId;

export const selectOrganizationName = (state) => state.user.organizationName;

export const selectPermissions = (state) => state.user.permissions;

export const selectPlanId = (state) => state.user.planId;

export const selectPlanClassification = (state) =>
  state.user.planClassification;

export const selectSubscriptionId = (state) => state.user.subscriptionId;

export const selectUpgradePath = (state) => state.user.upgradePath;

export const selectUserId = (state) => state.user.userId;

export const selectVisitorId = (state) => state.user.visitorId;

/* Composed Selectors */

export const selectUpsellSuccess = createSelector(
  (state) => state.user.billingChangeMessage,
  (billingChangeMessage) => !!billingChangeMessage?.success
);

export const selectFullName = createSelector(
  selectFirstName,
  selectLastName,
  (firstName, lastName) => formatFullName(firstName, lastName)
);

export const selectHasUnlimitedDownloads = createSelector(
  (state) => state.user.permissions.maxDownloadsPerMonth,
  (maxDownloadsPerMonth) => maxDownloadsPerMonth === UNLIMITED_DOWNLOADS
);

export const selectHasUnlimitedExports = createSelector(
  selectMaxExportsPerMonth,
  (maxExportsPerMonth) => maxExportsPerMonth === UNLIMITED_EXPORTS
);

export const selectIsAuthenticated = createSelector(
  selectEmail,
  (email) => !!email
);

export const selectIsLimitedStarterBundle = createSelector(
  selectPlanClassification,
  selectHasUnlimitedExports,
  (planClassification, hasUnlimitedExports) =>
    planClassification === PLAN_CLASSIFICATIONS.STARTER && !hasUnlimitedExports
);

export const selectMaxDownloadsPerMonth = createSelector(
  (state) => state.user.permissions.maxDownloadsPerMonth,
  (maxDownloadsPerMonth) =>
    // specific check for `undefined` since `null` means unlimited downloads
    maxDownloadsPerMonth === undefined ? 0 : maxDownloadsPerMonth
);

export const selectRemainingExports = createSelector(
  selectMaxExportsPerMonth,
  selectMonthlyExports,
  selectHasUnlimitedExports,
  (maxExportsPerMonth, monthlyExports, hasUnlimitedExports) =>
    hasUnlimitedExports
      ? UNLIMITED_EXPORTS
      : Math.max(maxExportsPerMonth - monthlyExports, 0)
);

export const shouldWatermark = createSelector(
  selectPermissions,
  (permissions) => {
    const hasUnlimitedExports =
      permissions.maxExportsPerMonth === UNLIMITED_EXPORTS;
    const hasHitExportLimit =
      permissions.monthlyExports >= permissions.maxExportsPerMonth;
    return !hasUnlimitedExports && hasHitExportLimit;
  }
);
