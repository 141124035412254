import { createSlice } from '@reduxjs/toolkit';
import { MediaTypes } from '@videoblocks/jelly-renderer';

import * as ContentTypes from '../../constants/ContentTypes';
import * as MediaActions from '../../constants/MediaActions';

export const DRAWERS = {
  STOCK: 'stock',
  UPLOADS: 'uploads',
  STYLES: 'styles',
  TEXT: 'text',
  OVERLAYS: 'overlays',
  LOGOS: 'logos',
  FOLDERS: 'folders',
  BRANDS: 'brands',
  TEMPLATES: 'templates',
};

export const initialState = {
  open: true,
  drawer: DRAWERS.STOCK,
  mediaType: MediaTypes.VIDEO,
  contentType: '',
  keywords: '',
  action: MediaActions.ADD,
  filters: {},
};

export const assetDrawerSlice = createSlice({
  name: 'assetDrawer',
  initialState,
  reducers: {
    openAssetDrawer: (state, action) => {
      state.open = true;
      state.contentType = action?.payload?.contentType ?? '';
      state.drawer = action?.payload?.drawer ?? DRAWERS.STOCK;
      state.filters = action?.payload?.filters ?? {};
      state.mediaType = action?.payload?.mediaType ?? MediaTypes.VIDEO; // Videos display before images in the stock drawer
      state.keywords = action?.payload?.keywords ?? '';
      state.action = action?.payload?.action ?? MediaActions.ADD;

      delete state['folderId'];
    },
    closeAssetDrawer: (state) => {
      state.open = false;
      state.drawer = '';
      state.mediaType = '';
      state.contentType = '';
      state.keywords = '';
      state.action = '';
      state.filters = {};
    },
    resetAssetDrawer: () => {
      return initialState;
    },
    changeMediaType: (state, action) => {
      state.mediaType = action?.payload ?? MediaTypes.VIDEO;
      state.keywords = '';
      state.filters = {};
      state.contentType = '';
      delete state['folderId'];
    },
    changeContentType: (state, action) => {
      state.contentType = action?.payload ?? '';
      if (state.contentType === ContentTypes.SFX) {
        state.filters = {};
      }
    },
    resetContentType: (state) => {
      state.contentType = '';
    },
    changeKeywords: (state, action) => {
      state.keywords = action?.payload ?? '';
    },
    changeFilters: (state, action) => {
      state.filters = action?.payload ?? {};
    },
    removeFilter: (state, action) => {
      const filter = action?.payload;
      if (filter) {
        state.filters[filter] = '';
      }
    },
    setFolderId: (state, action) => {
      state.folderId = action.payload;
      state.keywords = '';
    },
    setBrandName: (state, action) => {
      state.brandName = action.payload;
    },
    setBrandUid: (state, action) => {
      state.brandUid = action.payload;
    },
    setIsBrandUnsaved: (state, action) => {
      state.isBrandUnsaved = action.payload;
    },
  },
});

// actions
export const {
  openAssetDrawer,
  closeAssetDrawer,
  changeMediaType,
  changeContentType,
  resetContentType,
  changeKeywords,
  changeFilters,
  removeFilter,
  resetAssetDrawer,
  setFolderId,
  setBrandName,
  setBrandUid,
  setIsBrandUnsaved,
} = assetDrawerSlice.actions;

// selectors
export const selectOpen = (state) => state.assetDrawer.open;
export const selectDrawer = (state) => state.assetDrawer.drawer;
export const selectMediaType = (state) => state.assetDrawer.mediaType;
export const selectContentType = (state) => state.assetDrawer.contentType;
export const selectKeywords = (state) => state.assetDrawer.keywords;
export const selectAction = (state) => state.assetDrawer.action;
export const selectFilters = (state) => state.assetDrawer.filters;
export const selectFolderId = (state) => state.assetDrawer.folderId;

/**
 * FIXME: (MKR-307) this relates to the Brands Page as well as the Brands Drawer.
 * Ultimately should be moved to workspace slice?
 */
export const selectBrandName = (state) => state.assetDrawer.brandName;
export const selectBrandUid = (state) => state.assetDrawer.brandUid;
export const selectIsBrandUnsaved = (state) => state.assetDrawer.isBrandUnsaved;

export default assetDrawerSlice.reducer;
