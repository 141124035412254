import {
  Box,
  Button,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import { migrate } from '@videoblocks/jelly-scripts/dist';
import prettyBytes from 'pretty-bytes';
import { bool, number, string } from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Alert from '../../components/Alert';
import BorderLinearProgress from '../../components/BorderLinearProgress';
import { INTERCOM_EXPORT_FAQ_URL, PROJECTS_PATH } from '../../constants/Urls';
import { trackEvent } from '../../events/sendEvents';
import { EXPORT } from '../../events/tags';
import { useExportProgress } from '../../pusher/hooks';
import { calculateEstimatedFileSize } from '../../utils/calculateEstimatedFileSize';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
  },
  body: {
    marginBottom: theme.spacing(4),
  },
}));

const titleCopy = {
  pending: 'Your video is rendering',
  success: 'Your video is ready to download!',
  error: 'Your video encountered an error',
};

const bodyCopy = {
  pending:
    'You can wait on this page until your video has finished rendering, or you can view all of your pending and completed exports at any time on',
  success:
    'Your video has succesfully rendered! In addition to this page, all of your completed exports can be found on',
  error:
    'Your video encountered an error while rendering. You can view all of your pending and completed exports at any time on',
};
export default function ExportDetailsCard({
  cloudfrontUrl,
  exportId,
  hasError,
  isComplete,
  isOwner,
  name,
  resolution,
  size,
  status,
  storyboard,
}) {
  const classes = useStyles();
  const { search } = useLocation();

  const migratedStoryboard = storyboard ? migrate(JSON.parse(storyboard)) : {}; // FIXME avoid migrating outside hooks

  const { percentProgress } = useExportProgress(parseInt(exportId));

  const getStatusCopy = () => {
    if (status === 'pending') {
      if (percentProgress === 100) {
        return 'Finishing up';
      } else {
        return 'Rendering';
      }
    } else if (status === 'success') {
      return 'Complete';
    } else if (status === 'error') {
      return 'Error';
    }
    return '';
  };

  return (
    <div className={classes.card}>
      <Typography className={classes.title} variant="h4">
        {titleCopy[status]}
      </Typography>
      <Typography className={classes.body}>
        {bodyCopy[status]} the{' '}
        <Link
          component={RouterLink}
          color="secondary"
          target="_blank"
          rel="noopener"
          to={{ pathname: PROJECTS_PATH, search }}
        >
          My Projects
        </Link>{' '}
        page.
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Resolution</TableCell>
              <TableCell>File Size</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{name}</TableCell>
              <TableCell>{resolution ? `${resolution}p` : ''}</TableCell>
              <TableCell>
                {hasError ? (
                  '--'
                ) : size ? (
                  prettyBytes(size)
                ) : (
                  <>
                    &sim;{' '}
                    {prettyBytes(
                      calculateEstimatedFileSize(storyboard, resolution)
                    )}
                  </>
                )}
              </TableCell>
              <TableCell>{getStatusCopy()}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={4}>
        {hasError ? (
          <Alert severity="error">
            There was an error while rendering your video.{' '}
            <Link
              component={RouterLink}
              color="secondary"
              target="_blank"
              rel="noopener"
              to={{ pathname: INTERCOM_EXPORT_FAQ_URL }}
            >
              View common render issues.
            </Link>
          </Alert>
        ) : isComplete ? (
          <Button
            variant="contained"
            fullWidth
            color="primary"
            href={cloudfrontUrl}
            target="_blank"
            download={name}
            onClick={() =>
              trackEvent(EXPORT.DOWNLOAD, {
                exportId,
                isOwner,
                projectDuration: getStoryboardDuration(
                  migratedStoryboard?.items?.entities
                ),
              })
            }
            startIcon={<GetAppIcon />}
          >
            Download Video
          </Button>
        ) : (
          <BorderLinearProgress
            variant="determinate"
            value={Math.max(percentProgress, 5)} // Do not show progress <5% to account for "Starting up" phase
          />
        )}
      </Box>
    </div>
  );
}

ExportDetailsCard.propTypes = {
  cloudfrontUrl: string,
  exportId: string,
  hasError: bool,
  isComplete: bool,
  isOwner: bool,
  name: string,
  resolution: number,
  size: number,
  status: string,
  storyboard: string,
};
