import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Popover,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadRoundedIcon from '@material-ui/icons/CloudDownloadRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { getStoryboardDuration } from '@videoblocks/jelly-renderer';
import { migrate } from '@videoblocks/jelly-scripts/dist';
import { Share } from '@videoblocks/react-icons';
import copy from 'copy-to-clipboard';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import prettyBytes from 'pretty-bytes';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { INTERCOM_EXPORT_FAQ_URL, SHARE_PATH } from '../constants/Urls';
import { EXPORT_DRAWER } from '../events/locations';
import { trackEvent } from '../events/sendEvents';
import { EXPORT } from '../events/tags';
import useProject from '../hooks/useProject';
import useToast from '../hooks/useToast';
import { selectUserId } from '../selectors/user';
import formatTimestamp from '../utils/formatTimestamp';
import Alert from './Alert';
import Toast from './Toast';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 800,
    padding: theme.spacing(6, 4),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(40),
    fontWeight: theme.typography.fontWeightBold,
  },
  nameCell: {
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  link: {
    color: theme.palette.blue[700],
    textDecoration: 'underline',
  },
}));

export default function ProjectExportsDrawer(props) {
  const { onClose, open, projectId } = props;
  const classes = useStyles();

  const [errorPopoverAnchor, setErrorPopoverAnchor] = useState(null);
  const {
    open: isToastOpen,
    message,
    openToast,
    closeToast,
    severity,
  } = useToast();
  const { project } = useProject(projectId, true);
  const { name, exports: projectExports = [] } = project;
  const userId = useSelector(selectUserId);
  const isOwner = project.user_id === userId;

  const handleErrorClick = (event) => {
    setErrorPopoverAnchor(event.currentTarget);
  };

  const handleErrorPopoverClose = () => {
    setErrorPopoverAnchor(null);
  };

  const handleExportDownload = (exportId) => {
    trackEvent(EXPORT.DOWNLOAD, { exportId, isOwner });
  };

  const handleCopyShareLink = (shareLink) => {
    copy(shareLink);
    openToast(`Link has been copied to clipboard!`, 'success');
    trackEvent(EXPORT.COPY_SHARE_LINK, { location: EXPORT_DRAWER });
  };

  return (
    <Drawer
      anchor="right"
      open={open && !!project.name}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
    >
      <Toast open={isToastOpen} severity={severity} onClose={closeToast}>
        {message}
      </Toast>
      <div className={classes.title}>{name}</div>
      <Popover
        open={!!errorPopoverAnchor}
        anchorEl={errorPopoverAnchor}
        onClose={handleErrorPopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert severity="error" onClose={handleErrorPopoverClose}>
          There was an error while rendering your video.{' '}
          <a className={classes.link} href={INTERCOM_EXPORT_FAQ_URL}>
            View common render issues.
          </a>
        </Alert>
      </Popover>
      <Table aria-label="export table">
        <TableHead>
          <TableRow>
            <TableCell>File Name</TableCell>
            <TableCell>Length</TableCell>
            <TableCell>Ratio</TableCell>
            <TableCell>Resolution</TableCell>
            <TableCell>Size</TableCell>
            <TableCell colSpan={3}>Export Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {projectExports.map((project) => {
            const {
              cloudfront_url: cloudfrontUrl,
              completed_at: completedAt,
              created_at: createdAt,
              error_at: errorAt,
              share_id: shareId,
              id,
              resolution,
              size,
            } = project;
            const storyboard = migrate(JSON.parse(project.storyboard)); // FIXME avoid migrating outside hooks
            const shareLink = `${window.location.origin}${SHARE_PATH}/${shareId}`;

            const { name, ratio, items } = storyboard;
            const duration = getStoryboardDuration(items?.entities);
            const isComplete = !!completedAt;
            const hasError = !!errorAt;
            const date = parseISO(createdAt);

            return (
              <TableRow key={id}>
                <TableCell className={classes.nameCell} title={name}>
                  {name}
                </TableCell>
                <TableCell>{formatTimestamp(duration)}</TableCell>
                <TableCell>{ratio}</TableCell>
                <TableCell>{`${resolution}p`}</TableCell>
                <TableCell>{size ? prettyBytes(size) : '--'}</TableCell>
                <TableCell>{format(date, 'MM/dd/yy - hh:mm aa')}</TableCell>
                <TableCell align="right">
                  {isComplete ? (
                    <IconButton
                      title="Share"
                      color="secondary"
                      onClick={() => handleCopyShareLink(shareLink)}
                    >
                      <SvgIcon component={Share} fontSize="small" />
                    </IconButton>
                  ) : (
                    <Box padding={1.5}>
                      <CircularProgress color="secondary" size={24} />
                    </Box>
                  )}
                </TableCell>
                <TableCell align="right">
                  {hasError ? (
                    <IconButton
                      className={classes.errorIcon}
                      onClick={handleErrorClick}
                    >
                      <ErrorRoundedIcon />
                    </IconButton>
                  ) : isComplete ? (
                    <IconButton
                      title="Download"
                      color="secondary"
                      download={name}
                      href={cloudfrontUrl}
                      onClick={() => handleExportDownload(id)}
                    >
                      <CloudDownloadRoundedIcon />
                    </IconButton>
                  ) : (
                    <Box padding={1.5}>
                      <CircularProgress color="secondary" size={24} />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Drawer>
  );
}

ProjectExportsDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  project: PropTypes.number,
};

ProjectExportsDrawer.defaultProps = {
  onClose: () => {},
  open: false,
};
