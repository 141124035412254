import { reject } from 'lodash';
import log from 'loglevel';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useSWRInfinite } from 'swr';

import UploadAPI from '../api/UploadAPI';
import { trackEvent } from '../events/sendEvents';
import { UPLOAD_ASSOCIATE, UPLOAD_DISASSOCIATE } from '../events/tags';
import { selectIsEnterprise } from '../selectors/user';

export const DEFAULT_UPLOADS_PAGE_SIZE = 100;

export default function useUploads(mediaType, options = {}) {
  const {
    brandUid,
    isLogo = false,
    organization = undefined,
    pageSize = DEFAULT_UPLOADS_PAGE_SIZE,
  } = options;

  const isEnterprise = useSelector(selectIsEnterprise);

  const fetcher = async (url) => {
    if (url.includes('organization') && !isEnterprise) {
      return [];
    }
    try {
      const response = await new UploadAPI().get(url);
      return response.data ?? [];
    } catch (error) {
      log.error(`Error loading uploads: ${error?.message}`, { error });
      return [];
    }
  };

  const getKey = (index) => {
    const params = {
      brand_uid: brandUid,
      is_logo: isLogo ? 1 : undefined,
      media_type: mediaType,
      organization,
      page: index + 1,
      page_size: pageSize,
      arrayFormat: 'comma',
      skipNull: false, // guarantee that null orgIds from non-enterprise users are included in the query
    };
    return `/upload?${queryString.stringify(params)}`;
  };

  const { data, error, mutate, size, setSize } = useSWRInfinite(
    getKey,
    fetcher
  );

  const disassociateUpload = async ({
    uploadId,
    brandUid,
    templateUid,
    projectUid,
  }) => {
    mutate(reject(data, ['uploadId', uploadId]), false);
    await new UploadAPI().disassociateUpload({
      uploadId,
      brandUid,
      templateUid,
      projectUid,
    });
    mutate();
    trackEvent(UPLOAD_DISASSOCIATE, {
      uploadId,
      brandUid,
      templateUid,
      projectUid,
    });
  };

  const associateUpload = async ({
    uploadId,
    brandUid,
    templateUid,
    projectUid,
  }) => {
    mutate(reject(data, ['uploadId', uploadId]), false);
    await new UploadAPI().associateUpload({
      uploadId,
      brandUid,
      projectUid,
      templateUid,
    });
    mutate();
    trackEvent(UPLOAD_ASSOCIATE, {
      uploadId,
      brandUid,
      templateUid,
      projectUid,
    });
  };

  const updateUpload = async (uploadId, data) => {
    await new UploadAPI().updateUpload({ uploadId, data });
    mutate();
  };

  const deleteUpload = async (id) => {
    mutate(reject(data, ['id', id]), false);
    await new UploadAPI().deleteUpload(id);
    mutate();
  };

  const refetch = () => {
    mutate();
  };

  const isEmpty = data?.[0]?.length === 0;
  const isLoadingInitialData = !data && !error;
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < pageSize);
  const loadMore = useCallback(() => setSize(size + 1), [size, setSize]);

  /*
   * SWR Infinite makes multiple page calls dependent on size. Combines results of those calls:
   * [[page1], [page2], [page3], ...] => [page1, page2, page3, ...]
   */
  const results = data ? data.flat(1) : [];

  return {
    data: results.sort((a, b) => (a?.pivot?.is_default ? -1 : 1)), // sort default to top
    disassociateUpload,
    associateUpload,
    updateUpload,
    deleteUpload,
    isLoadingMore,
    isReachingEnd,
    loadMore,
    refetch,
  };
}
