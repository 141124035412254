import { migrate } from '@videoblocks/jelly-scripts/dist';
import { pick } from 'lodash';
import log from 'loglevel';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import CustomTemplatesAPI, {
  TEMPLATES_FETCH_URL,
} from '../api/CustomTemplatesAPI';
import { trackEvent } from '../events/sendEvents';
import { TEMPLATE } from '../events/tags';
import { selectIsOrgAdmin, selectUserId } from '../selectors/user';

export default function useTemplates() {
  const userId = useSelector(selectUserId);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  const personalFilter = (template) => template.user_id === userId;
  const orgFilter = (template) =>
    !!template.organization_id && template.team_access;
  const orgAgnosticFilter = (template) => !template.organization_id;

  const templateFetcher = async (url) => {
    try {
      const response = await new CustomTemplatesAPI().get(url);
      response.data = (response.data || []).map((template) => ({
        ...template,
        storyboard: migrate(template.storyboard),
      }));
      return response;
    } catch (error) {
      log.error(`Error loading templates: ${error?.message}`, { error });
      return { data: [] };
    }
  };

  const { data: templatesData, mutate: mutateTemplates } = useSWR(
    TEMPLATES_FETCH_URL,
    templateFetcher
  );

  const isLoading = !templatesData;
  const moreTemplateResultsExist = !!templatesData?.next_page_url;
  const hasPersonalTemplates =
    templatesData?.data?.filter(personalFilter).length > 0;
  const hasOrgTemplates = templatesData?.data?.filter(orgFilter).length > 0;

  /**
   * @param {Template} template
   * @param {Object} additionalAnalyticsArgs
   * @returns {Promise<string>} templateUid
   */
  const createTemplate = async (template, additionalAnalyticsArgs = {}) => {
    templatesData.data.unshift(template);
    mutateTemplates(templatesData, false);

    const { template_uid } = await new CustomTemplatesAPI().postTemplate(
      template
    );

    mutateTemplates();

    trackEvent(TEMPLATE.CREATE, {
      templateId: template_uid,
      firstForMe: !hasPersonalTemplates,
      firstForOrg: !hasOrgTemplates,
      isOrgAdmin,
      ratio: template.ratio,
      ...additionalAnalyticsArgs,
    });

    return template_uid;
  };

  /**
   * @param {Project} project
   * @returns {Promise<string>} templateUid
   */
  const createTemplateFromProject = async (project, initialValues = {}) => {
    const template = Object.assign(
      {},
      pick(project, ['brand_uid', 'storyboard', 'name', 'ratio']),
      initialValues
    );
    template.user_id = userId;
    // TODO the name inside the storyboard overrides the toplevel name-- can we simplfy on the BE?
    if (initialValues?.name) {
      template.storyboard.name = initialValues.name;
    }

    if (templatesData?.data) {
      templatesData.data.unshift(template);
      mutateTemplates(templatesData, false);
    }

    const { template_uid } = await new CustomTemplatesAPI().postTemplate(
      template
    );

    mutateTemplates();

    trackEvent(TEMPLATE.CREATE, {
      firstForMe: !hasPersonalTemplates,
      firstForOrg: !hasOrgTemplates,
      isOrgAdmin,
      fromProjectId: project.uid,
      templateId: template_uid,
    });

    return template_uid;
  };

  const deleteTemplate = async (uid) => {
    trackEvent(TEMPLATE.DELETE, { templateId: uid, isOrgAdmin });

    mutateTemplates(
      {
        ...templatesData,
        data: templatesData.data.filter((t) => t.uid !== uid),
      },
      false
    );

    await new CustomTemplatesAPI().deleteTemplate(uid);

    mutateTemplates();
  };

  const duplicateTemplate = async (template) => {
    const { uid: newUid } = await new CustomTemplatesAPI().duplicateTemplate(
      template.uid
    );

    trackEvent(TEMPLATE.COPY, {
      templateId: template.uid,
      isOrgAdmin,
      isOwner: template.user_id === userId,
      newUid,
    });

    mutateTemplates();
  };

  const setTemplateTeamAccess = async (uid, teamAccess) => {
    mutateTemplates(
      {
        ...templatesData,
        data: templatesData.data.map((template) => ({
          ...template,
          team_access: template.uid === uid ? teamAccess : template.team_access,
        })),
      },
      false
    );

    await new CustomTemplatesAPI().setTemplateTeamAccess(uid, teamAccess);

    mutateTemplates();
  };

  return {
    createTemplate,
    createTemplateFromProject,
    deleteTemplate,
    duplicateTemplate,
    setTemplateTeamAccess,
    myTemplates: templatesData?.data?.filter(personalFilter), // allow undefined to be passed when loading
    orgTemplates: templatesData?.data?.filter(orgFilter), // allow undefined to be passed when loading
    orgAgnosticTemplates: templatesData?.data?.filter(orgAgnosticFilter), // allow undefined to be passed when loading
    moreTemplateResultsExist,
    isLoading,
  };
}
