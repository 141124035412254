import { migrate } from '@videoblocks/jelly-scripts/dist';
import log from 'loglevel';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import ProjectsAPI from '../api/ProjectsAPI';
import { trackEvent, trackProjectTeamAccess } from '../events/sendEvents';
import { PROJECT } from '../events/tags';
import { selectIsEnterprise, selectUserId } from '../selectors/user';
import useContentToken from './useContentToken';

/*
 * TODO MKR-321: Now that UID's are avilable in all projects, ensure that this
 * file is updated to use UID's instead of ID's for tracking when a full cutover
 * to UID's is started. Each method should only be supplied with UID's at that
 * point, we'll also need to rename id -> uid everywhere for consistency.
 */

const buildProjectsUrl = (projectsPage) =>
  `/project?${queryString.stringify({ page: projectsPage })}`;

const buildOrgProjectsUrl = (orgPage) =>
  `/project?${queryString.stringify({
    page: orgPage,
    include_team_projects: true,
  })}`;

// Since projects are sorted by recently updated, prefetching the surrounding pages avoids "jittering" when paginating
const refreshSurroundingPages = (urlBuilder, page, moreResultsExist) => {
  if (page !== 1) mutate(urlBuilder(page - 1), true);
  if (moreResultsExist) mutate(urlBuilder(page + 1), true);
};

export default function useProjects(
  currentProjectsPage = 1,
  currentOrgPage = 1
) {
  const isEnterprise = useSelector(selectIsEnterprise);
  const userId = useSelector(selectUserId);
  const { contentToken } = useContentToken();

  const myProjectFetcher = async (url) => {
    try {
      const response = await new ProjectsAPI(contentToken).get(url);
      response.data = (response.data || []).map((project) => ({
        ...project,
        storyboard: migrate(project.storyboard),
      }));
      return response;
    } catch (error) {
      log.error(`Error loading projects: ${error?.message}`, {
        error,
      });
      return { data: [] };
    }
  };

  const orgProjectFetcher = async (url, isEnterprise) => {
    if (!isEnterprise) {
      return { data: [] };
    }
    try {
      const response = await new ProjectsAPI(contentToken).get(url);
      response.data = (response.data || []).map((project) => ({
        ...project,
        storyboard: migrate(project.storyboard),
      }));
      return response;
    } catch (error) {
      log.error(`Error loading org projects: ${error?.message}`, { error });
      return { data: [] };
    }
  };

  const { data: projectsData, mutate: mutateMyProjects } = useSWR(
    buildProjectsUrl(currentProjectsPage),
    myProjectFetcher
  );

  const { data: orgProjectsData, mutate: mutateOrgProjects } = useSWR(
    buildOrgProjectsUrl(currentOrgPage),
    (url) => orgProjectFetcher(url, isEnterprise)
  );

  const isLoading = !projectsData || !orgProjectsData;
  const moreProjectsResultsExist = !!projectsData?.next_page_url;
  const moreOrgResultsExist = !!orgProjectsData?.next_page_url;

  const prefetchPages = () => {
    refreshSurroundingPages(
      buildProjectsUrl,
      currentProjectsPage,
      moreProjectsResultsExist
    );
    refreshSurroundingPages(
      buildOrgProjectsUrl,
      currentOrgPage,
      moreOrgResultsExist
    );
  };

  /**
   * @param {Project} project
   * @param {Object} additionalAnalyticsArgs
   * @returns {Promise<string>} projectUid
   */
  const createProject = async (project, additionalAnalyticsArgs = {}) => {
    (projectsData?.data || []).unshift(project);
    mutateMyProjects(projectsData, false);

    const { project_id } = await new ProjectsAPI().postProject(project);

    mutateMyProjects();

    trackEvent(PROJECT.CREATE, {
      projectId: project_id,
      ratio: project.ratio,
      ...additionalAnalyticsArgs,
    });

    return project_id;
  };

  const deleteProject = async (uid) => {
    trackEvent(PROJECT.DELETE, { projectUid: uid });

    mutateMyProjects(
      {
        ...projectsData,
        data: projectsData.data.filter((t) => t.uid !== uid),
      },
      false
    );
    if (isEnterprise) {
      mutateOrgProjects(
        {
          ...orgProjectsData,
          data: orgProjectsData.data.filter((t) => t.uid !== uid),
        },
        false
      );
    }

    await new ProjectsAPI().deleteProject(uid);

    mutateMyProjects();
    if (isEnterprise) {
      mutateOrgProjects();
    }

    prefetchPages();
  };

  /**
   * @param {Project} project
   */
  const duplicateProject = async (project) => {
    const originalProjectUid = project.uid;

    project.uid = null;
    projectsData.data.unshift(project);
    mutateMyProjects(projectsData, false);

    const { uid: newUid } = await new ProjectsAPI().duplicateProject(
      originalProjectUid
    );

    trackEvent(PROJECT.DUPLICATE, {
      projectUid: originalProjectUid,
      isOwner: project.user_id === userId,
      newUid,
    });

    mutateMyProjects();

    prefetchPages();
  };

  const setProjectTeamAccess = async (id, teamAccess) => {
    const projects = projectsData.data;
    const project = projects.find((proj) => proj.id === id);
    trackProjectTeamAccess(teamAccess, project.id);

    if (!project) {
      log.error(
        `Error setting team access for project[id=${id}]: project not found`
      );
    }

    mutateMyProjects(
      {
        ...projectsData,
        data: projects.map((project) => ({
          ...project,
          team_access: project.id === id ? teamAccess : project.team_access,
        })),
      },
      false
    );

    await new ProjectsAPI().setProjectTeamAccess(project.uid, teamAccess);

    mutateMyProjects();
    mutateOrgProjects();

    prefetchPages();
  };

  const getMoreResultsExist = (forMyProjects = true) => {
    return forMyProjects ? moreProjectsResultsExist : moreOrgResultsExist;
  };

  const getCurrentPage = (forMyProjects = true) => {
    return forMyProjects ? currentProjectsPage : currentOrgPage;
  };

  return {
    getMoreResultsExist,
    getCurrentPage,
    createProject,
    deleteProject,
    duplicateProject,
    isLoading,
    myProjects: projectsData?.data || undefined, // allow undefined to be passed when loading
    orgProjects: orgProjectsData?.data || undefined, // allow undefined to be passed when loading
    setProjectTeamAccess,
  };
}
