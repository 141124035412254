import { makeStyles } from '@material-ui/core/styles';
import { range } from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { TIMELINE_LEFT_PADDING } from '../../constants/Constants';
import { translateX } from '../../utils/cssUtils';
import formatTimeStamp from '../../utils/formatTimestamp';
import { getInterval, secondsToPixels } from '../../utils/timelineUtils';

const useStyles = makeStyles((theme) => ({
  rulerContainer: {
    position: 'relative',
    flexShrink: 0,
    marginBottom: theme.spacing(1.5),
    height: theme.spacing(2),
    backgroundColor: theme.palette.grey[900],
    overflow: 'hidden',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  ruler: {
    position: 'relative',
    height: '100%',
    paddingLeft: TIMELINE_LEFT_PADDING,
  },
  interval: {
    position: 'absolute',
    height: '100%',
    // tick mark
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderColor: 'currentColor',
    // half tick mark
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
      width: 1,
      height: '50%',
      backgroundColor: 'currentColor',
    },
  },
  label: {
    position: 'absolute',
    bottom: 0,
    marginLeft: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    color: 'currentColor',
  },
}));

function TimelineRuler(props) {
  const { duration = 0, scrollX = 0, zoom = 0 } = props;
  const classes = useStyles();

  const interval = getInterval(zoom);
  const numTicks = Math.floor(duration / interval);
  const tickWidth = secondsToPixels(interval, zoom);

  const style = {
    transform: `translate3d(-${scrollX}px, 0, 0)`,
  };

  return (
    <div className={classes.rulerContainer}>
      <div className={classes.ruler} style={style}>
        {range(numTicks).map((index) => {
          const seconds = index * interval;
          const offset = tickWidth * index;
          const style = {
            transform: translateX(offset),
            width: tickWidth,
          };
          return (
            <div className={classes.interval} style={style} key={index}>
              <small className={classes.label}>
                {formatTimeStamp(seconds, { padMinutes: false })}
              </small>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TimelineRuler.propTypes = {
  duration: PropTypes.number,
  scrollX: PropTypes.number,
  zoom: PropTypes.number,
};

export default memo(TimelineRuler);
