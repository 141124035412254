import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
  SB_AUDIO_URL,
  SB_IMAGES_URL,
  SB_VIDEO_URL,
} from '../../constants/Urls';

const useStyles = makeStyles((theme) => ({
  nav: {
    marginLeft: theme.spacing(2),
    height: '100%',
    '&:hover > $link': {
      color: theme.palette.grey[500],
      '&:hover': {
        color: theme.palette.pink[500],
      },
    },
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    height: 'calc(100% + 1px)',
    borderTop: '3px solid transparent',
    borderBottom: '3px solid transparent',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  selectedLink: {
    color: theme.palette.pink[500],
    borderTopColor: 'currentColor',
  },
  sup: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
}));

export default function SiteLinks() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <nav className={classes.nav}>
      <Link className={classes.link} color="inherit" href={SB_VIDEO_URL}>
        Video
      </Link>
      <Link className={classes.link} color="inherit" href={SB_AUDIO_URL}>
        Audio
      </Link>
      <Link className={classes.link} color="inherit" href={SB_IMAGES_URL}>
        Images
      </Link>
      <Link
        className={clsx(classes.link, classes.selectedLink)}
        component={RouterLink}
        to={{
          pathname: '/',
          search: history.location.search,
        }}
      >
        Maker&nbsp;<sup className={classes.sup}>New</sup>
      </Link>
    </nav>
  );
}
