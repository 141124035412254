export function updateIntercom(settings) {
  if (window.Intercom) {
    /*
     * The update method is throttled to 20 calls per 30 min,
     * but duplicates within the last 60 seconds only count as 1
     * per the docs and the official questions site.
     */
    window.Intercom('update', {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      ...settings,
    });
  }
}

export function hideMessenger() {
  updateIntercom({ hide_default_launcher: true });
}

export function showMessenger() {
  updateIntercom({ hide_default_launcher: false });
}
