import log from 'loglevel';
import { useSelector } from 'react-redux';
import useSWR, { mutate } from 'swr';

import BrandsAPI from '../api/BrandsAPI';
import { selectIsEnterprise, selectIsOrgAdmin } from '../selectors/user';

const fetcher = async (url) => {
  try {
    return await new BrandsAPI().get(url);
  } catch (error) {
    log.error(`Error loading brand: ${error?.message}`, { error });
    return {};
  }
};

export default function useBrand(brandUid) {
  const isEnterprise = useSelector(selectIsEnterprise);
  const isOrgAdmin = useSelector(selectIsOrgAdmin);

  const { data: brand, mutate: mutateBrand } = useSWR(
    brandUid ? `/brands/${brandUid}` : null,
    fetcher
  );

  const editBrand = async ({
    newDetail = brand?.detail,
    isDraft = brand?.is_draft,
    isDefault = brand?.is_default,
  }) => {
    if (isEnterprise && isOrgAdmin) {
      if (brand?.uid !== brandUid) {
        log.error(`Error updating brand[uid=${brandUid}]: brand not found`);
      }

      // update local data immediately, but disable the revalidation
      mutateBrand(
        {
          ...brand,
          detail: newDetail,
          is_draft: isDraft,
          is_default: isDefault,
        },
        false
      );

      // send a request to the API to update the source
      await new BrandsAPI().putBrand(brandUid, newDetail, isDraft, isDefault);

      // trigger a revalidation (refetch) to make sure our local data is correct
      mutateBrand();
      mutate('/brands');
    }
  };

  return { brand, editBrand, isLoading: !brand };
}
