import AbstractAPI from './AbstractAPI';

export default class TransloaditAPI extends AbstractAPI {
  getSignatureAuth() {
    return this.get('transloadit');
  }

  getTemplateSignatureAuth(templateType = 'default') {
    return this.get(`transloadit?template_type=${templateType}`);
  }
}
