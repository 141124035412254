import { MediaTypes } from '@videoblocks/jelly-renderer';

import { SOURCE_TYPES } from '../constants/Constants';
import { DEFAULT_ITEM_DURATION } from '../constants/Storyboard';
import createBaseObject from './createBaseObject';

export default function createImageObject(item = {}) {
  return createBaseObject({
    // base attributes
    duration: DEFAULT_ITEM_DURATION,
    mediaType: MediaTypes.IMAGE,

    // sourced attributes
    itemSourceId: item.id,
    itemSourceType: item.sourceType || SOURCE_TYPES.STOCK, // stock || upload
    source: item.preview_url || item?.files?.[0]?.url,
    title: item.title || item.name,
    isForeground: 1,
    isLogo: item.isLogo ?? false,
    scale: 0.5,

    // image attributes
    filters: {},
    position: { x: 'center', y: 'center' },
    opacity: 1,
    fadeIn: 0,
    fadeOut: 0,

    // brand attributes
    isBranded: item.isBranded ?? false,
  });
}
