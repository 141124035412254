import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MediaTypes } from '@videoblocks/jelly-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  SEARCH_RESULTS_PAGE_SIZE,
  SOURCE_TYPES,
} from '../../constants/Constants';
import { trackEvent } from '../../events/sendEvents';
import { SIDE_DRAWER } from '../../events/tags';
import {
  openConfirmationDialog,
  openUploadDialog,
} from '../../features/ui/uiSlice';
import useUploads from '../../hooks/useUploads';
import { selectIsEnterprise } from '../../selectors/user';
import { selectWorkspaceProjectId } from '../../selectors/workspace';
import formatPropsToCamelCase from '../../utils/formatPropsToCamelCase';
import LoadMoreButton from '../LoadMoreButton';
import UploadPreview from '../UploadPreview';
import AssetDrawerHeader from './AssetDrawerHeader';
import BrandLogos from './BrandLogos';
import ResultsGrid from './ResultsGrid';
import UploadButton from './UploadButton';

const useStyles = makeStyles((theme) => ({
  drawer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    overflowY: 'scroll',
  },
  images: {
    paddingLeft: theme.spacing(2),
  },
}));

const mediaType = MediaTypes.IMAGE;
const isLogo = true;

export default function LogosDrawer(props) {
  const { onSelect = () => {} } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { projectId, templateId } = useParams();

  const isEnterprise = useSelector(selectIsEnterprise);
  const isEditingTemplate = !!templateId;
  const selectedTemplateId = useSelector(selectWorkspaceProjectId); // stores UID of either project or template

  const {
    data,
    deleteUpload,
    associateUpload,
    isLoadingMore,
    isReachingEnd,
    loadMore,
    refetch,
  } = useUploads(mediaType, { isLogo, pageSize: SEARCH_RESULTS_PAGE_SIZE });

  const handleUploadClick = () => {
    dispatch(
      openUploadDialog({
        isLogo,
        refetch,
        templateUid: isEditingTemplate ? selectedTemplateId : undefined,
      })
    );
  };

  const handleLogoDelete = async (event, upload) => {
    dispatch(
      openConfirmationDialog({
        title: 'Delete Logo?',
        text: (
          <>
            It will no longer be available to add to projects
            {isEnterprise ? ' and templates' : ''}.
          </>
        ),
        confirmButtonText: 'Delete',
        danger: true,
        showCancel: true,
        onConfirm: async () => {
          await deleteUpload(upload.id);
          trackEvent(SIDE_DRAWER.UPLOAD_DELETE, formatPropsToCamelCase(upload));
        },
      })
    );
  };

  const handleLogoSelect = async (event, logo, isBranded) => {
    onSelect(null, {
      ...logo,
      mediaType: MediaTypes.IMAGE,
      sourceType: SOURCE_TYPES.UPLOAD,
      isForeground: isLogo,
      isLogo: isLogo,
      scale: 0.2,
      isBranded,
    });
    await associateUpload({
      uploadId: logo.id,
      projectUid: projectId,
      templateUid: templateId,
    });
  };

  const handleLoadMore = () => {
    loadMore();
  };

  return (
    <div className={classes.drawer}>
      <div className={classes.content}>
        {isEnterprise ? (
          <BrandLogos
            onUploadSelect={(event, item) =>
              handleLogoSelect(event, item, true)
            }
          />
        ) : (
          <AssetDrawerHeader mediaType={[MediaTypes.IMAGE]} />
        )}

        <Grid container spacing={2} className={classes.images}>
          <Grid item xs={12}>
            <ResultsGrid>
              <UploadButton onClick={handleUploadClick} />
              {data.map((item) => (
                <UploadPreview
                  mediaType={MediaTypes.IMAGE}
                  onSelect={handleLogoSelect}
                  onDelete={handleLogoDelete}
                  key={item.id}
                  upload={item}
                />
              ))}
            </ResultsGrid>
            <LoadMoreButton
              isLoadingMore={isLoadingMore}
              isReachingEnd={isReachingEnd}
              onClick={handleLoadMore}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
